.banner {
  min-height: 100vh;
  overflow: visible;
}

.bannerImgWrapper{
  overflow: visible;
  align-items: center;
}

.bannerImgWrapper img{
  position: absolute;
  right: -18rem;
  overflow: visible;
  height: 49rem;
  object-fit: cover;
  width: auto;
  z-index: -1;
}

.m50-top {
  margin-top: 50px;
}

.nowrap{
  flex-wrap: none;
}

.special_amp{
font-family: "Baskerville","Palatino Linotype","Palatino","Times New Roman",serif;
font-style: italic;
font-size: 1.0em;
line-height: 0.5em;
font-weight: normal;
}

.banner h6 {
  font-weight: 300;
  letter-spacing: 1px;
  margin-bottom: 15px;
}

.banner h1 {
font-weight: 700;
font-family: "Cormorant Garamond", serif;
margin-bottom: 15px;
line-height: normal;
}

.banner h4 {
font-family: Lato-regular;
letter-spacing: 2px;
}

.social_icon {
margin-top: 40px;
}

.social_icon a {
width: 55px;
height: 55px;
line-height: 60px;
text-align: center;
border-radius: 50%;
background: rgba(255, 255, 255, 0.05);
margin-right: 15px;
}

.social_icon a:hover {
  background: rgba(111, 111, 111, 0.32);
}

.mobileNoTopMargin {
  margin-top: 80px;
}

.img {
position: absolute;
right: 15%;
bottom: 0;
width: 600px;
z-index: 2;
}

.banner a i {
font-size: 25px;
line-height: -1;
}

@media screen and (max-width: 767px){
  
  .banner h1{
    font-size: 60px;
  }

  .banner h5 {
    font-family: Lato-Light;
    color: #d3d3d3 !important;
  }

  .mobileFont{
    font-family: Lato-Light;
    color: #d3d3d3 !important;
    font-weight: 600;
  }

  .bannerImgWrapper img {
    height: 40rem;
    left: -28rem;
    top: -40px;
    opacity: .8;
  }
 
  .mobileSpaceBetween {
    position: relative;
    top: -67px;
  }

  .mobileNoTopMargin {
    margin-top: 0 !important;
  }
 
  .social_icon {
    margin-top: 1rem!important;
    position: relative;
    bottom: -6.5rem;
  }

  .social_icon a{
    background: rgba(255, 255, 255, 0.15);
  }

  .social_icon a:hover{
    background: rgb(2, 200, 200, 0.15);
  }

  .mobileNoPadding{
    padding-bottom: 0.5rem!important;
  }

  .mobileNoMarginLeft{
    margin-left: 2px!important;
    color: white! important;
  }

  .mobileMargin{
    margin-bottom: 10px;
  }

}
