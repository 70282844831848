@media only screen and (max-width: 800px) {
    .fs-3{
        font-size: 18px !important;
    }

    .section-padding{
        padding: 80px 0 !important;
    }

    .services .item-box:first-child{
        padding: 5px !important;
    }

    .sec-head{
        margin-bottom: 0px !important;
    }

    .mobileTopMargin{
        margin-top: -60px;
    }


}
