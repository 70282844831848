.googleReview{
  border-radius: 30px;
  box-sizing: border-box;
  outline-style: none;
  background-color: rgb(42, 42, 42);
  padding: 10px;
  max-height: 460px;
  overflow-y: scroll; /* Add the ability to scroll */
  margin: 10px;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.googleReview::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.googleReview {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.header{
  margin-bottom: 12px;
  box-sizing: border-box;
  outline-style: none;
  display: flex;
  flex-wrap: nowrap;
  max-width: 100%;
}

.icon{
  align-self: flex-start;
  margin-right: 12px;
}

.reviewAvatarContainer{
  width: 40px;
  height: 40px;
  position: relative;
}

.reviewAvatar{
  position: relative;
  width: 40px;
  height: 40px;
  box-shadow: rgba(17, 17, 17, 0.1) 0px 0px 2px inset;
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
  border-bottom-left-radius: 50%;
  overflow-x: hidden;
  overflow-y: hidden;
}

.reviewAvatarBackground::after{
  content: "";
  position: absolute;
  top: 0px;
  display: block;
  width: inherit;
  height: inherit;
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
  border-bottom-left-radius: 50%;
  box-shadow: rgba(17, 17, 17, 0.1) 0px 0px 2px inset;
}

.reviewAuthor{
  display: flex;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
  min-width: 0px;
  max-width: 100%;
}

.reviewAuthor:hover{
  display: flex !important;
}

.authorContainer{
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  text-align: left;
  align-self: flex-start;
}

.reviewBadget{
  display: flex;
  margin-left: 5px;
}

.reviewBadget svg {
  width: 14px;
  height: 14px;
}

.reviewInfo{
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  flex-wrap: wrap;
  font-size: 12px;
  line-height: 14px;
  color: grey
}

.reviewData{
  white-space: pre;
  display: inline-flex;
}

.date{
  display: inline;
}

.content{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.raitingContainer{
  display: flex;
-webkit-box-align: center;
align-items: center;
justify-content: left;
}

.raitingBar{
  display: inline-flex;
flex-wrap: nowrap;
-webkit-box-align: center;
align-items: center;
margin-left: 5px;
}

.reviewText p{
  font-size: large;
}

@media only screen and (max-width: 800px){
  .reviewText p{
    font-size: large;
  }
}

