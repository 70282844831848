.clRose span{
  color: #d18787 !important;
  font-size: 2rem;
}

.clGold span {
  color: #ffd700 !important;
}
.threeDots{
  position: relative;
}
.greenbtn {
  background-color: #439b17 !important;
  font-family: Lato-regular;
  padding: 10px;
}

.threeDots span{
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: #ffd700;
  position: absolute;
  left: -10px;
  bottom: 15px;
}

.threeDots span:first-of-type{
  bottom: 21px;
}

.threeDots span:last-of-type{
  left: -16px;
}

.img{

  border-radius: 50%;
  height: 200px;
  width: 200px;
  margin-left: -200px;
}

.pos_relative{
  position: relative;
}

.bgImg1{
  background-image: url("../../../public/assets/img/transparent_bg/Homepage\ Fotos\ \(1772\ x\ 1185\)\ Transparenter\ Hintergrund\ \(2\).png");
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  position: absolute;
  background-position-x: -5.5rem ;
}

.moreHight {
  height: 300px;
}

@media only screen and (max-width: 800px){

  .callToAction >div{
      display: flex;
      justify-content: center;
      flex-basis: content;
      margin-top: 20px;
  }

  .bgImg1{
    background-position-x: 0!important;
  }

  .moreHight {
    height: auto;
    margin-bottom: 20px !important;
  }

  .pt{
    padding-top: 0px !important;
  }

  .mobileLargeRow {
    height: 260px;
  }

  .mobileBigMargin{
      margin-top: 80px;
  }

  .mobilePadding{
      padding: 10px !important;
      margin-top: 20px;
  }
}

.bgImg{
  background-image: url("../../../public/assets/img/transparent_bg/Homepage\ Fotos\ \(1772\ x\ 1185\)\ Transparenter\ Hintergrund\ \(3\).png");
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  position: absolute;
  left: -22px;
}

.smallLineHeight {
  line-height: normal;
  font-size: 6rem !important;
}


ul.stars li::before {
  content: "✨";
  margin-right: 10px;
}


@media only screen and (max-width: 800px) {
  .bgMobile{
    font-size: 3rem !important;
    bottom: 11rem !important;
  }

  .smallLineHeight{
    line-height: 3.5rem;
  }

  .mobileLargeRow {
    height: 250px;
  }

  .mobileMinMarginTop{
    margin-top: -60px;
  }


  .mobileExtraMargin{
    margin-top: 200px !important
  }
}