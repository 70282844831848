.special img {
  max-width: 100%;
  width: auto;
}

.video iframe{
  width: 80%;
  height: 600px;
}

.logo{
  width: 50px;
  margin-left: 15px;
  margin-top:0.5rem;
}

@media only screen and (max-width: 800px) {
  .video iframe{
    height: 23rem;
    width: 100%;
  }
}