
@media only screen and (max-width: 800px) {
  .mobileH6{
    font-size: 0.9rem;
  }

  .mobileNoMargin{
    width: 100%;
    padding: 0 !important;
  }
  
  .mobileColMargin{
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
}
