@media only screen and (max-width: 800px){
  .mobileCenter{
      display: flex;
      justify-content: center;
  }

  .smallOnMobile{
    font-size: 0.8rem;
  }
}

.footer {
  background-color: var(--bg-main-color);
}