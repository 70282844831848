@import url("styles/global/fonts.css");
@import url("styles/global/blog.css");


:root{
  --color-paragraph: rgb(255, 255, 255);
  --color-navbar: rgb(237, 237, 237);
  --color-rose: #d18787;
  --color-gold:  #ffd700;
  --color-main-text: #ffffff;
  --color-secondary-text: #dad6d6;
  --bg-main-color: #42444a;
  --color-post-date-bg: var(--color-rose);
  --color-post-topics: var(--color-gold);
  --color-blog-card-border: #d18787a3;
}
.slick-dots li.slick-active button:before {
  color: white !important;
 }
 
 .float-start {
  float: left;
 }

 button.active::after{
  content: ""; 
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(120deg, #fcef00, #fff200);
  opacity: .7;
}

 .float-end {
  float: right
 }

 .slick-dots li button:before {
  color: lightgrey !important;
 }

 h1 {
  font-family: Lato-Black;
 }


 h2 {
  font-family: Lato-Bold;
 }

 h3 {
  font-family: Lato-Regular;
 }

 h4 {
  font-family: Lato-Light;
 }

 h5 {
  font-family: Lato-Thin;
 }

* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  outline: none;
  list-style: none;
}

.my-border {
  border: 2px solid var(--color-blog-card-border);
  border-radius: 10px; 
  overflow: hidden;
}


#impressum a{
  color: blue;
}

.cursor-pointer{
  cursor: pointer!important;
}

#impressum h1,
#impressum h2,
#impressum h3{
  padding-bottom: 20px;
  padding-top: 20px;
}

#impressum h2{
  font-size: 1.8rem;
}

#impressum h3{
  font-size: 1.5rem;
}

#impressum h4{
  font-size: 1.2rem;
  padding-bottom: 10px;
}

#impressum h5{
  font-size: 1.0rem;
  padding-bottom: 10px;
}

#impressum p{
  padding-bottom: 10px;
}

html, body {
  font-family: 'Lato-Light', sans-serif;
  font-weight: 400; 
}

html,
body {
  padding: 0;
  margin: 0;
  color: #fff;
  background: var(--bg-main-color);
  line-height: 1.5;
  overflow-x: hidden !important;
}


.hideX #preloader {
  display: none !important;
  z-index: -1111111;
  height: 0;
  width: 0;
  overflow: hidden;
}
.hideX .pace {
  display: none !important;
}


@media screen and (max-width: 991px) {
  .collapse {
    display: block !important;
    height: 0;
    padding: 0;
    opacity: 0;
  }
  .navbar .navbar-collapse.show-with-trans {
    opacity: 1;
    padding: 10px 15px;
    height: auto;
    transition: all 0.3s linear;
  }
}
.objfit {
  object-fit: cover;
}

.fs-3{
  font-size: 22px;
}

.fs-4{
  font-size: 20px;
}
.fs-1{
  font-size: 26px;
}

.fs-18 {
  font-size:20px;
}

.toUpperCase{
  text-transform: uppercase;
}

img {
  width: 100%;
  height: auto;
}

span,
a,
a:hover {
  display: inline-block;
  color: inherit;
    text-decoration: none;
}

.h-300 {
  height: 300px;
}

.h-200 {
  height: 200px;
}
b, strong {
  font-family: 'Lato-Bold', sans-serif !important;
}

.h-250 {
  height: 250px;
}

h1 {
  font-size: 55px;
}

h2 {
  font-size: 40px;
}

h3 {
  font-size: 35px;
}

h4 {
  font-size: 30px;
}

h5 {
  font-size: 25px;
}

h6 {
  font-size: 18px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  line-height: 1.4;
  font-weight: 600;
}

p {
  color: var(--color-paragraph);
  font-size: 15px;
  font-weight: 300;
  line-height: 2;
  margin: 0;
}

a,
span {
  display: inline-block;
}
a:hover,
span:hover {
  text-decoration: none;
}

.container {
  max-width: 1600px;
}

.adjustStyles{
  background-color: var(--bg-main-color) !important;
  color: var(--color-paragraph) !important
}

@media screen and (max-width: 1600px) {
  .container {
    max-width: 1200px;
  }
}

.custom-font {
  font-family: "Barlow Condensed", sans-serif;
}

/* ====================== [ End Typography  ] ====================== */
/* ====================== [ Start Helper Classes  ] ====================== */
.o-hidden {
  overflow: hidden;
}

.position-re {
  position: relative;
}

.ontop {
  position: relative;
  z-index: 7;
}

.full-over {
  position: absolute !important;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.full-width {
  width: 100% !important;
}

.main-color {
  color: #75dab4 !important;
}

.bg-gray {
  background-color: #f8f4f3;
}
.bg-gray .primery-shadow {
  -webkit-box-shadow: 0px 4px 30px -4px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 4px 30px -4px rgba(0, 0, 0, 0.2);
}

.bg-dark {
  background-color: #141414 !important;
}
.bg-dark .primery-shadow {
  -webkit-box-shadow: 0px 4px 40px -4px rgba(255, 255, 255, 0.2);
  box-shadow: 0px 4px 40px -4px rgba(255, 255, 255, 0.2);
}

.bg-color {
  background-color: #75dab4 !important;
}
.bg-color .primery-shadow {
  -webkit-box-shadow: 0px 4px 40px -4px rgba(255, 255, 255, 0.2);
  box-shadow: 0px 4px 40px -4px rgba(255, 255, 255, 0.2);
}

.sub-bg {
  background: #11141b;
}

.bg-img {
  background-size: cover;
  background-repeat: no-repeat;
}

.bg-center {
  background-position: center center;
}

.bg-fixed {
  background-attachment: fixed;
}

.bg-repeat {
  background-size: auto;
  background-repeat: repeat;
}

.section-padding {
  padding: 120px 0;
}

.section-padding-onlyTop{
  padding-top: 120px;
}

.section-ptb {
  padding: 80px 0;
}

.cmd-padding {
  padding: 0 10px;
}

.csm-padding {
  padding: 0 5px;
}

.head-pt {
  padding: 80px 0 0;
}

.head-pt-small{
  padding: 80px;
}

.head-pb {
  padding: 0 0 80px;
}

.rounded {
  border-radius: 5px;
}

.circle {
  border-radius: 50%;
}

.unlist {
  list-style: none;
}

.undecro:hover {
  text-decoration: none;
}

.inline {
  display: inline-block;
}

.valign {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.primery-shadow {
  -webkit-box-shadow: 0px 8px 30px -4px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 8px 30px -4px rgba(0, 0, 0, 0.1);
}

.padding5 {
  padding-right: 5%;
  padding-left: 5%;
}

/*-------------------------------------------------------
					 text color
-------------------------------------------------------*/
.text-white {
  color: #fff;
}

.text-black {
  color: #000;
}

.text-extra-light-gray {
  color: #b7b7b7;
}

/*-------------------------------------------------------
					font-weight
-------------------------------------------------------*/
.fw-100 {
  font-weight: 100 !important;
}

.fw-200 {
  font-weight: 200 !important;
}

.fw-300 {
  font-weight: 300 !important;
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.fw-800 {
  font-weight: 800 !important;
}

.fw-900 {
  font-weight: 900 !important;
}

/*-------------------------------------------------------
					 font-size
-------------------------------------------------------*/
.fz-10 {
  font-size: 10px !important;
}

.fz-12 {
  font-size: 12px !important;
}

.fz-14 {
  font-size: 14px !important;
}

.fz-15 {
  font-size: 15px !important;
}

.fz-16 {
  font-size: 16px !important;
}

.fz-18 {
  font-size: 18px !important;
}

.fz-20 {
  font-size: 20px !important;
}

.fz-22 {
  font-size: 22px !important;
}

.fz-25 {
  font-size: 25px !important;
}

.fz-30 {
  font-size: 30px !important;
}

.fz-40 {
  font-size: 40px !important;
}

.fz-50 {
  font-size: 50px !important;
}

/*-------------------------------------------------------
					line-height
-------------------------------------------------------*/
.line-height-10 {
  line-height: 10px;
}

.line-height-13 {
  line-height: 13px;
}

.line-height-18 {
  line-height: 18px;
}

.line-height-20 {
  line-height: 20px;
}

.line-height-24 {
  line-height: 24px;
}

.line-height-22 {
  line-height: 22px;
}

.line-height-26 {
  line-height: 26px;
}

.line-height-28 {
  line-height: 28px;
}

.line-height-30 {
  line-height: 30px;
}

.line-height-35 {
  line-height: 35px;
}

.line-height-40 {
  line-height: 40px;
}

.line-height-45 {
  line-height: 45px;
}

.line-height-50 {
  line-height: 50px;
}

.line-height-55 {
  line-height: 55px;
}

.line-height-60 {
  line-height: 60px;
}

.line-height-65 {
  line-height: 65px;
}

.line-height-70 {
  line-height: 70px;
}

.line-height-75 {
  line-height: 75px;
}

.line-height-80 {
  line-height: 80px;
}

.line-height-85 {
  line-height: 85px;
}

.line-height-90 {
  line-height: 90px;
}

.line-height-95 {
  line-height: 95px;
}

.line-height-100 {
  line-height: 100px;
}

.line-height-110 {
  line-height: 110px;
}

.line-height-120 {
  line-height: 120px;
}

/*-------------------------------------------------------
					text-transform
-------------------------------------------------------*/
.text-u {
  text-transform: uppercase !important;
}

.text-l {
  text-transform: lowercase !important;
}

.text-c {
  text-transform: capitalize !important;
}

.text-non {
  text-transform: none !important;
}

.text-i {
  font-style: italic;
}

/*-------------------------------------------------------
					 letter-spacing
-------------------------------------------------------*/
.ls0 {
  letter-spacing: 0px !important;
}

.ls1 {
  letter-spacing: 1px !important;
}

.ls2 {
  letter-spacing: 2px !important;
}

.ls3 {
  letter-spacing: 3px !important;
}

.ls4 {
  letter-spacing: 4px !important;
}

.ls5 {
  letter-spacing: 5px !important;
}

.ls10 {
  letter-spacing: 10px !important;
}

/*-------------------------------------------------------
			 margin-top  (0/100) +5
-------------------------------------------------------*/
.mt-0 {
  margin-top: 0 !important;
}

.mt-5 {
  margin-top: 5px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mt-15 {
  margin-top: 15px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-25 {
  margin-top: 25px !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.mt-35 {
  margin-top: 35px !important;
}

.mt-40 {
  margin-top: 40px !important;
}

.mt-45 {
  margin-top: 45px !important;
}

.mt-50 {
  margin-top: 50px !important;
}

.mt-55 {
  margin-top: 55px !important;
}

.mt-60 {
  margin-top: 60px !important;
}

.mt-62 {
  margin-top: 62px !important;
}

.mt-65 {
  margin-top: 65px !important;
}

.mt-70 {
  margin-top: 70px !important;
}

.mt-75 {
  margin-top: 75px !important;
}

.mt-80 {
  margin-top: 80px !important;
}

.mt-85 {
  margin-top: 85px !important;
}

.mt-90 {
  margin-top: 90px !important;
}

.mt-95 {
  margin-top: 95px !important;
}

.mt-100 {
  margin-top: 100px !important;
}

.mt-150 {
  margin-top: 150px !important;
}

/*-------------------------------------------------------
			 margin-bottom  (0/100) +5
-------------------------------------------------------*/
.mb-0 {
  margin-bottom: 0 !important;
}

.mb-5 {
  margin-bottom: 5px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-15 {
  margin-bottom: 15px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-25 {
  margin-bottom: 25px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.mb-35 {
  margin-bottom: 35px !important;
}

.mb-40 {
  margin-bottom: 40px !important;
}

.mb-45 {
  margin-bottom: 45px !important;
}

.mb-50 {
  margin-bottom: 50px !important;
}

.mb-55 {
  margin-bottom: 55px !important;
}

.mb-60 {
  margin-bottom: 60px !important;
}

.mb-62 {
  margin-bottom: 62px !important;
}

.mb-65 {
  margin-bottom: 65px !important;
}

.mb-70 {
  margin-bottom: 70px !important;
}

.mb-75 {
  margin-bottom: 75px !important;
}

.mb-80 {
  margin-bottom: 80px !important;
}

.mb-85 {
  margin-bottom: 85px !important;
}

.mb-90 {
  margin-bottom: 90px !important;
}

.mb-95 {
  margin-bottom: 95px !important;
}

.mb-100 {
  margin-bottom: 100px !important;
}
label {
  margin-bottom: 0 !important;
}
.ms-50{
  margin-left: 50px !important;
}

.ms-40 {
  margin-left: 40px !important;
}

.ms-30 {
  margin-left: 30px !important;
}

.ms-10 {
  margin-left: 10px !important;
}

.ms-20{
  margin-left: 10px !important
}
/*-------------------------------------------------------
			padding-top  (0/100) +5
-------------------------------------------------------*/
.pt-0 {
  padding-top: 0 !important;
}

.pt-5 {
  padding-top: 5px !important;
}

.pt-10 {
  padding-top: 10px !important;
}

.pt-15 {
  padding-top: 15px !important;
}

.pt-20 {
  padding-top: 20px !important;
}

.pt-25 {
  padding-top: 25px !important;
}

.pt-30 {
  padding-top: 30px !important;
}

.pt-35 {
  padding-top: 35px !important;
}

.pt-40 {
  padding-top: 40px !important;
}

.pt-45 {
  padding-top: 45px !important;
}

.pt-50 {
  padding-top: 50px !important;
}

.pt-55 {
  padding-top: 55px !important;
}

.pt-60 {
  padding-top: 60px !important;
}

.pt-65 {
  padding-top: 65px !important;
}

.pt-70 {
  padding-top: 70px !important;
}

.pt-75 {
  padding-top: 75px !important;
}

.pt-80 {
  padding-top: 80px !important;
}

.pt-85 {
  padding-top: 85px !important;
}

.pt-90 {
  padding-top: 90px !important;
}

.pt-95 {
  padding-top: 95px !important;
}

.pt-100 {
  padding-top: 100px !important;
}

/*-------------------------------------------------------
		 padding-bottom  (0/100) +5
-------------------------------------------------------*/
.pb-0 {
  padding-bottom: 0 !important;
}

.pb-5 {
  padding-bottom: 5px !important;
}

.pb-10 {
  padding-bottom: 10px !important;
}

.pb-15 {
  padding-bottom: 15px !important;
}

.pb-20 {
  padding-bottom: 20px !important;
}

.pb-25 {
  padding-bottom: 25px !important;
}

.pb-30 {
  padding-bottom: 30px !important;
}

.pb-35 {
  padding-bottom: 35px !important;
}

.pb-40 {
  padding-bottom: 40px !important;
}

.pb-45 {
  padding-bottom: 45px !important;
}

.pb-50 {
  padding-bottom: 50px !important;
}

.pb-55 {
  padding-bottom: 55px !important;
}

.pb-60 {
  padding-bottom: 60px !important;
}

.pb-65 {
  padding-bottom: 65px !important;
}

.pb-70 {
  padding-bottom: 70px !important;
}

.pb-75 {
  padding-bottom: 75px !important;
}

.pb-80 {
  padding-bottom: 80px !important;
}

.pb-85 {
  padding-bottom: 85px !important;
}

.pb-90 {
  padding-bottom: 90px !important;
}

.pb-95 {
  padding-bottom: 95px !important;
}

.pb-100 {
  padding-bottom: 100px !important;
}

.no-padding {
  padding: 0 !important;
}

.pe-10{
  padding-right: 10px;
}

.ps-10{
  padding-left: 10px;
}

.ps-20{
  padding-left: 20px;
}

.ps-30{
  padding-left: 30px;
}
.img-250{
  height: 250px;
  width: 250px;
}
/* ====================== [ End Helper Classes  ] ====================== */
/* ====================== [ Start Preloader ] ====================== */
.pace {
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  z-index: 99999999999999;
  position: fixed;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 400px;
  border: 0px;
  height: 1px;
  overflow: hidden;
  background: rgba(255, 255, 255, 0.05);
  -webkit-transition: all 1s;
  -o-transition: all 1s;
  transition: all 1s;
}
.pace .pace-progress {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  max-width: 300px;
  position: fixed;
  z-index: 99999999999999;
  display: block;
  position: absolute;
  top: 0;
  right: 100%;
  height: 100%;
  width: 100%;
  background: #d18787
}

.pace.pace-inactive {
  width: 100vw;
  opacity: 0;
}
.pace.pace-inactive .pace-progress {
  max-width: 100vw;
}

#preloader {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: fixed;
  z-index: 9999999;
}
#preloader:after,
#preloader:before {
  content: "";
  position: fixed;
  left: 0;
  height: 50%;
  width: 100%;
  background: #252531;
  -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  -o-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}
#preloader:before {
  top: 0;
}
#preloader:after {
  bottom: 0;
}
#preloader.isdone {
  visibility: hidden;
  -webkit-transition-delay: 1.5s;
  -o-transition-delay: 1.5s;
  transition-delay: 1.5s;
}
#preloader.isdone:after,
#preloader.isdone:before {
  height: 0;
  -webkit-transition: all 0.7s cubic-bezier(1, 0, 0.55, 1);
  -o-transition: all 0.7s cubic-bezier(1, 0, 0.55, 1);
  transition: all 0.7s cubic-bezier(1, 0, 0.55, 1);
  -webkit-transition-delay: 1s;
  -o-transition-delay: 1s;
  transition-delay: 1s;
}

.loading-text {
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: 10px;
  position: absolute;
  top: calc(50% - 30px);
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  color: #75dab4;
  font-family: "Teko", sans-serif;
  z-index: 9999;
}
.loading-text.isdone {
  top: 50%;
  opacity: 0;
  -webkit-transition: all 0.8s cubic-bezier(0.19, 1, 0.22, 1);
  -o-transition: all 0.8s cubic-bezier(0.19, 1, 0.22, 1);
  transition: all 0.8s cubic-bezier(0.19, 1, 0.22, 1);
  -webkit-transition-delay: 0.5s;
  -o-transition-delay: 0.5s;
  transition-delay: 0.5s;
}

/* ====================== [ End Preloader ] ====================== */
/* ====================== [ Start Text Animation & Images ] ====================== */
.splitting.animated .char {
  -webkit-animation: fadeInUp 0.4s cubic-bezier(0.3, 0, 0.7, 1) both;
  animation: fadeInUp 0.4s cubic-bezier(0.3, 0, 0.7, 1) both;
  -webkit-animation-delay: calc(30ms * var(--char-index));
  animation-delay: calc(30ms * var(--char-index));
}

.splitting .whitespace {
  width: 5px;
}

@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

.splitting.txt.animated .char {
  -webkit-animation: fadeIn 0.3s cubic-bezier(0.3, 0, 0.7, 1) both;
  animation: fadeIn 0.3s cubic-bezier(0.3, 0, 0.7, 1) both;
  -webkit-animation-delay: calc(10ms * var(--char-index));
  animation-delay: calc(10ms * var(--char-index));
}

.splitting.txt .whitespace {
  width: 5px;
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.imago {
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
  clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
  -webkit-transition: all 0.8s;
  -o-transition: all 0.8s;
  transition: all 0.8s;
  -webkit-transition-delay: 0.3s;
  -o-transition-delay: 0.3s;
  transition-delay: 0.3s;
}
.imago.animated {
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
}

/* ====================== [ End Text Animation & Images ] ====================== */
/* ====================== [ Start Cursor Style ] ====================== */
/*
body {
  cursor: none;
}
*/
.mouse-cursor {
  position: fixed;
  left: 0;
  top: 0;
  pointer-events: none;
  border-radius: 50%;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  visibility: hidden;
}

.cursor-inner {
  margin-left: 2px;
  margin-top: 2px;
  width: 6px;
  height: 6px;
  z-index: 10000001;
  background-color: #75dab4;
  -webkit-transition: width 0.3s ease-in-out, height 0.3s ease-in-out,
    margin 0.3s ease-in-out, opacity 0.3s ease-in-out;
  -o-transition: width 0.3s ease-in-out, height 0.3s ease-in-out,
    margin 0.3s ease-in-out, opacity 0.3s ease-in-out;
  transition: width 0.3s ease-in-out, height 0.3s ease-in-out,
    margin 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.cursor-inner.cursor-hover {
  margin-left: -40px;
  margin-top: -40px;
  width: 80px;
  height: 80px;
  background-color: #75dab4;
  opacity: 0.3;
}

.cursor-outer {
  margin-left: -15px;
  margin-top: -15px;
  width: 40px;
  height: 40px;
  border: 1px solid #75dab4;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  z-index: 10000000;
  opacity: 0.5;
  -webkit-transition: all 0.08s ease-out;
  -o-transition: all 0.08s ease-out;
  transition: all 0.08s ease-out;
}
.cursor-outer.cursor-hover {
  opacity: 0;
}

/* ====================== [ End Cursor Style ] ====================== */
/* ====================== [ Start progress-wrap ] ====================== */
.progress-wrap {
  position: fixed;
  bottom: 30px;
  right: 30px;
  height: 44px;
  width: 44px;
  cursor: pointer;
  display: block;
  border-radius: 50px;
  z-index: 100;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateY(20px);
  -ms-transform: translateY(20px);
  transform: translateY(20px);
  -webkit-transition: all 400ms linear;
  -o-transition: all 400ms linear;
  transition: all 400ms linear;
}

.progress-wrap.active-progress {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}

.progress-wrap::after {
  position: absolute;
  font-family: "Font Awesome 5 Free";
  content: "\f077";
  text-align: center;
  line-height: 44px;
  font-size: 13px;
  font-weight: 900;
  color: #75dab4;
  left: 0;
  top: 0;
  height: 44px;
  width: 44px;
  cursor: pointer;
  display: block;
  z-index: 1;
  -webkit-transition: all 400ms linear;
  -o-transition: all 400ms linear;
  transition: all 400ms linear;
}

.progress-wrap svg path {
  fill: none;
}

.progress-wrap svg.progress-circle path {
  stroke: #75dab4;
  stroke-width: 4;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 400ms linear;
  -o-transition: all 400ms linear;
  transition: all 400ms linear;
}

/* ====================== [ End progress-wrap ] ====================== */
/* ====================== [ Start Overlay ] ====================== */
[data-overlay-dark],
[data-overlay-light] {
  position: relative;
}

[data-overlay-dark] .container,
[data-overlay-light] .container {
  position: relative;
  z-index: 2;
}

[data-overlay-dark]:before,
[data-overlay-light]:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
}

[data-overlay-dark]:before {
  background: var(--bg-main-color);
}

[data-overlay-light]:before {
  background: #fff;
}

[data-overlay-dark] h1,
[data-overlay-dark] h2,
[data-overlay-dark] h3,
[data-overlay-dark] h4,
[data-overlay-dark] h5,
[data-overlay-dark] h6,
[data-overlay-dark] span,
.bg-dark h1,
.bg-dark h2,
.bg-dark h3,
.bg-dark h4,
.bg-dark h5,
.bg-dark h6,
.bg-dark span,
.bg-color h1,
.bg-color h2,
.bg-color h3,
.bg-color h4,
.bg-color h5,
.bg-color h6,
.bg-color span {
  color: #fff;
}

[data-overlay-dark] p,
.bg-dark p,
.bg-color p {
  color: #dad6d6;
}

[data-overlay-dark="0"]:before,
[data-overlay-light="0"]:before {
  opacity: 0;
}

[data-overlay-dark="1"]:before,
[data-overlay-light="1"]:before {
  opacity: 0.1;
}

[data-overlay-dark="2"]:before,
[data-overlay-light="2"]:before {
  opacity: 0.2;
}

[data-overlay-dark="3"]:before,
[data-overlay-light="3"]:before {
  opacity: 0.3;
}

[data-overlay-dark="4"]:before,
[data-overlay-light="4"]:before {
  opacity: 0.4;
}

[data-overlay-dark="5"]:before,
[data-overlay-light="5"]:before {
  opacity: 0.5;
}

[data-overlay-dark="6"]:before,
[data-overlay-light="6"]:before {
  opacity: 0.6;
}

[data-overlay-dark="7"]:before,
[data-overlay-light="7"]:before {
  opacity: 0.7;
}



[data-overlay-dark="8"]:before,
[data-overlay-light="8"]:before {
  opacity: 0.8;
}

[data-overlay-dark="9"]:before,
[data-overlay-light="9"]:before,
[data-overlay-color="9"]:before {
  opacity: 0.9;
}

[data-overlay-dark="10"]:before,
[data-overlay-light="10"]:before {
  opacity: 1;
}

/* ====================== [ End Overlay ] ====================== */
/* ====================== [ Start Heading ] ====================== */
.sec-head {
  position: relative;
  margin: 0 auto 40px;
}
.sec-head h6 {
  color: #ffd700;
  font-weight: 400;
  font-size: 22px;
  text-transform: uppercase;
  letter-spacing: 14px;
  margin-bottom: 0px;
}
.sec-head h3 {
  font-size: 80px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 4px;
}
.sec-head .tbg {
  position: absolute;
  bottom: 2rem;
  left: 0;
  width: 100%;
  text-align: center;
  font-size: 10rem;
  font-weight: 700;
  color: rgba(255, 255, 255, 0.02);
  -webkit-text-stroke: 1px rgba(255, 255, 255, 0.25);
  opacity: 1;
}

.sec-head .tbg.small{
  font-size: 5vw!important;
  bottom: 110px;
}

@media only screen and (max-width: 800px) {
  .sec-head .tbg{
    position: relative !important;
    bottom: 9rem;
    font-size: 3.5rem;
    color: rgba(255, 255, 255, 0.1);
  -webkit-text-stroke: 1px rgba(255, 255, 255, 0.5);
  }
}
.sec-head.tr-head {
  text-align: right;
  margin-bottom: 0;
}
.sec-head.tr-head .tbg {
  text-align: left;
}

.sub-title {
  position: relative;
  padding-left: 20px;
  margin-bottom: 20px;
}
.sub-title h6 {
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 300;
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 4px;
}
.sub-title span {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: #ffd700;
  position: absolute;
  left: 6px;
  bottom: 5px;
}
.sub-title span:first-of-type {
  bottom: 11px;
}
.sub-title span:last-of-type {
  left: 0px;
}

.main-title {
  font-weight: 600;
  line-height: 1.5;
  margin-bottom: 15px;
}

.extra-title {
  font-weight: 700;
}

.sm-title {
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.smore {
  position: relative;
  font-size: 20px;
  font-weight: 300;
  letter-spacing: 2px;
  text-transform: uppercase;
  display: inline-block;
  padding: 5px;
}
.smore:after {
  content: "";
  width: 45%;
  height: 100%;
  background: rgba(255, 255, 255, 0.03);
  position: absolute;
  left: 0;
  top: 0;
}
.smore i {
  margin-left: 10px;
}

/* ====================== [ End Heading ] ====================== */
/* ====================== [  Start Button Style ] ====================== */
.btn-curve {
  background: transparent;
  padding: 12px 35px;
  border: 1px solid #1e1e1e;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.btn-curve.inverted {
  background: var(--color-rose);
}
.btn-curve:focus {
  outline: none;
}
.btn-curve:hover {
  outline: none;

}
.btn-curve:hover:after {
  bottom: -30px;
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
}
.btn-curve:hover span {
  color: #fff;
}
.btn-curve:after {
  content: "";
  position: absolute;
  top: -30px;
  left: -30px;
  right: -30px;
  bottom: 130%;
  background: #1e1e1e;
  -webkit-transform: rotate(-5deg);
  -ms-transform: rotate(-5deg);
  transform: rotate(-5deg);
  -webkit-transition: all 0.8s;
  -o-transition: all 0.8s;
  transition: all 0.8s;
}
.btn-curve span {
  font-size: 13px;
  font-weight: 500;
  /*text-transform: uppercase;*/
  letter-spacing: 2px;
  position: relative;
  z-index: 2;
  -webkit-transition: all 0.8s;
  -o-transition: all 0.8s;
  transition: all 0.8s;
  text-align: center;
}

.btn-border{
  border: 1px;
  border-style: solid;
  border-color: grey;
}

.btn-to-bottom {
  position: absolute;
  bottom: 5px;
}
.btn-curve.btn-lit {
  border-color: #fff;
  color: #fff;
}
.btn-curve.btn-lit:hover span {
  color: #ffd700;
}
.btn-curve.btn-lit:after {
  background: #d18787;
}

.btn-curve.btn-lit.inverted:after {
  background: #1e1e1e;
}

.btn-curve.btn-blc {
  background: #1e1e1e;
}
.btn-curve.btn-blc:after {
  background: #fff;
}
.btn-curve.btn-blc span {
  color: #fff;
}
.btn-curve.btn-blc:hover span {
  color: #1e1e1e;
}
.btn-curve.btn-color {
  background: #75dab4;
  border-color: #75dab4;
}
.btn-curve.btn-color:after {
  background: #fff;
}
.btn-curve.btn-color span {
  color: #0c0f16;
}
.btn-curve.btn-color:hover span {
  color: #75dab4;
}
.btn-curve.btn-lg {
  padding: 14px 40px;
}
.btn-curve.btn-lg span {
  font-size: 14px;
}
.btn-curve.btn-wit {
  background: #fff;
  border-color: #fff;
}
.btn-curve.btn-wit:after {
  background: #090909;
}
.btn-curve.btn-wit span {
  color: #1e1e1e;
}
.btn-curve.btn-wit:hover span {
  color: #fff;
}
.btn-curve.btn-radius {
  border-radius: 30px;
}

.simple-btn {
  position: relative;
  padding: 7px;
  display: inline-block;
  text-transform: uppercase;
  letter-spacing: 4px;
  font-size: 13px;
  font-weight: 300;
}
.simple-btn:after {
  content: "";
  width: 45%;
  height: 100%;
  background: rgba(255, 255, 255, 0.05);
  position: absolute;
  left: 0;
  top: 0;
}
.simple-btn.right:after {
  left: auto;
  right: 0;
}

/* ====================== [ End Button Style ] ====================== */
/* ====================== [ Start navbar ] ====================== */
.navbar {
  position: absolute;
  left: 0;
  top: 0;
  padding: 0;
  margin: 0;
  width: 100%;
  background: transparent;
  border-bottom: 2px solid transparent;
  z-index: 999;
  min-height: 80px;
}
.navbar .icon-bar {
  color: #fff;
}

.navbar .navbar-nav .nav-link {
  font-size: 1.2rem;
  font-weight: 500;
  color: var(--color-navbar);
  padding: 15px 7px;
  margin: 10px 10px;
  cursor: pointer;
}

.nav-item {
  font-family: 'Lato-Light';
}

@media only screen and (max-width: 800px){
  .navbar .navbar-nav .nav-link{
    font-size: 1.1rem!important ;
    font-weight: 300;
  }
}

.navbar .navbar-nav .dropdown-menu {
  padding: 10px 0;
  border: 0;
  -webkit-box-shadow: 0px 10px 40px -4px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 10px 40px -4px rgba(0, 0, 0, 0.1);
}
.navbar .navbar-nav .dropdown-item {
  font-size: 13px;
  padding: 10px 20px;
  opacity: 0.9;
}
.navbar .navbar-nav .dropdown-item:hover {
  background: transparent;
  color: #75dab4;
}
.navbar .dropdown-menu {
  display: block;
  opacity: 0;
  visibility: hidden;
  border-radius: 0;
  color: #fff;
  background: #1a1e25;
  min-width: 185px;
  -webkit-transform: translateY(20px);
  -ms-transform: translateY(20px);
  transform: translateY(20px);
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
}
.navbar .dropdown-menu.show {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}
.navbar .dropdown-menu .dropdown-item {
  color: #fff;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
  padding: 10px 25px;
  position: relative;
}
.navbar .dropdown-menu .dropdown-item:after {
  content: "";
  width: 0px;
  height: 1px;
  background: #75dab4;
  position: absolute;
  left: 10px;
  top: 50%;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
}
.navbar .dropdown-menu .dropdown-item:hover {
  padding-left: 30px;
}
.navbar .dropdown-menu .dropdown-item:hover:after {
  width: 10px;
}
.navbar .search {
  color: #fff;
  padding-left: 30px;
  border-left: 1px solid rgba(255, 255, 255, 0.1);
}
.navbar .search .icon {
  cursor: pointer;
}
.navbar .search .search-form {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  background: rgba(37, 37, 49, 0.98);
  display: none;
}
.navbar .search .search-form form {
  width: 600px;
  position: absolute;
  left: calc(50% - 300px);
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.navbar .search .search-form form input {
  padding: 15px 0;
  font-size: 80px;
  color: #fff;
  text-transform: uppercase;
  text-align: left;
  background: transparent;
  border: 0;
  border-bottom: 2px solid #75dab4;
}
.navbar .search .search-form .close {
  position: absolute;
  top: 20%;
  right: 20%;
  color: #fff;
  font-size: 60px;
  opacity: 1;
  cursor: pointer;
}
.navbar .logo {
  width: 50px;
}

.nav-scroll {
  background: #272727;
  border-color: #3c3c3c;
  padding: 0;
  position: fixed;
  top: -80px;
  -webkit-transition: -webkit-transform 0.5s;
  transition: -webkit-transform 0.5s;
  -o-transition: transform 0.5s;
  transition: transform 0.5s;
  transition: transform 0.5s, -webkit-transform 0.5s;
  -webkit-transform: translateY(80px);
  -ms-transform: translateY(80px);
  transform: translateY(80px);
}

.topnav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999999;
  padding: 30px 0;
  color: #fff;
  -webkit-transition: all 0.7s;
  -o-transition: all 0.7s;
  transition: all 0.7s;
}
.topnav .container,
.topnav .container-fluid {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.topnav .container-fluid {
  padding: 0 50px;
}
.topnav .logo {
  width: 50px;
}
.topnav .menu-icon {
  margin-left: auto;
  cursor: pointer;
}
.topnav .menu-icon:hover .text .char {
  -webkit-animation: fadeInUp 0.3s cubic-bezier(0.3, 0, 0.7, 1) both;
  animation: fadeInUp 0.3s cubic-bezier(0.3, 0, 0.7, 1) both;
  -webkit-animation-delay: calc(30ms * var(--char-index));
  animation-delay: calc(30ms * var(--char-index));
}
.topnav .menu-icon .text {
  font-size: 13px;
  font-weight: 500;
  padding-left: 15px;
  display: table-cell;
  vertical-align: middle;
  position: relative;
}
.topnav .menu-icon .text:after {
  content: "close";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding-left: 15px;
  opacity: 0;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
  -webkit-transition-delay: 0.4s;
  -o-transition-delay: 0.4s;
  transition-delay: 0.4s;
}
.topnav .menu-icon .text .word {
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
  -webkit-transition-delay: 0.6s;
  -o-transition-delay: 0.6s;
  transition-delay: 0.6s;
}
.topnav .menu-icon .text.open .word {
  opacity: 0;
  -webkit-transition-delay: 0.4s;
  -o-transition-delay: 0.4s;
  transition-delay: 0.4s;
}
.topnav .menu-icon .text.open:after {
  opacity: 1;
  -webkit-transition-delay: 0.6s;
  -o-transition-delay: 0.6s;
  transition-delay: 0.6s;
}
.topnav .menu-icon .icon {
  display: table-cell;
}
.topnav .menu-icon .icon i {
  display: block;
  width: 20px;
  height: 3px;
  background: #fff;
  margin: 4px 0;
}
.topnav.dark .menu-icon {
  color: #000;
}
.topnav.dark .menu-icon .icon i {
  background: #000;
}
.topnav.dark.navlit .menu-icon {
  color: #fff;
}
.topnav.dark.navlit .menu-icon .icon i {
  background: #fff;
}

.hamenu {
  position: fixed;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100vh;
  background: #11141b;
  padding: 120px 30px 30px;
  overflow: hidden;
  z-index: 9999;
  -webkit-transition: all 0.5s cubic-bezier(1, 0, 0.55, 1);
  -o-transition: all 0.5s cubic-bezier(1, 0, 0.55, 1);
  transition: all 0.5s cubic-bezier(1, 0, 0.55, 1);
}
.hamenu.open .menu-links .main-menu > li .link {
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition-delay: 1s;
  -o-transition-delay: 1s;
  transition-delay: 1s;
}
.hamenu.open .cont-info:after {
  height: 100vh;
  -webkit-transition-delay: 1s;
  -o-transition-delay: 1s;
  transition-delay: 1s;
}
.hamenu.open .cont-info .item {
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
  -webkit-transition-delay: 1s;
  -o-transition-delay: 1s;
  transition-delay: 1s;
}
.hamenu .menu-links {
  color: #fff;
  margin-top: 30px;
}
.hamenu .menu-links .o-hidden {
  display: inline-block;
}
.hamenu .menu-links .main-menu {
  position: relative;
  z-index: 2;
}
.hamenu .menu-links .main-menu.gosub > li {
  opacity: 0;
  visibility: hidden;
  -webkit-transition-delay: 0.2s !important;
  -o-transition-delay: 0.2s !important;
  transition-delay: 0.2s !important;
}
.hamenu .menu-links .main-menu.gosub > li .link {
  -webkit-transform: translateY(45px) !important;
  -ms-transform: translateY(45px) !important;
  transform: translateY(45px) !important;
  opacity: 0 !important;
  visibility: hidden !important;
  -webkit-transition-delay: 0.2s !important;
  -o-transition-delay: 0.2s !important;
  transition-delay: 0.2s !important;
}
.hamenu .menu-links .main-menu.gosub .sub-menu.sub-open {
  z-index: 3 !important;
  opacity: 1 !important;
  visibility: visible !important;
}
.hamenu .menu-links .main-menu > li {
  font-size: 30px;
  font-weight: 600;
  padding: 10px;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  opacity: 1;
  visibility: visible;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}
.hamenu .menu-links .main-menu > li span.nm {
  opacity: 0.8;
  font-size: 11px;
  margin-right: 10px;
}
.hamenu .menu-links .main-menu > li .link {
  -webkit-transform: translateY(45px);
  -ms-transform: translateY(45px);
  transform: translateY(45px);
  -webkit-transition: all 0.7s;
  -o-transition: all 0.7s;
  transition: all 0.7s;
  cursor: pointer;
}
.hamenu .menu-links .main-menu > li .link i {
  font-size: 15px;
  margin-left: 5px;
}
.hamenu .menu-links .main-menu .sub-menu {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  z-index: -1;
  -webkit-transition-delay: 0.2s;
  -o-transition-delay: 0.2s;
  transition-delay: 0.2s;
}
.hamenu .menu-links .main-menu .sub-menu.sub-open {
  z-index: 3;
}
.hamenu .menu-links .main-menu .sub-menu.sub-open li .sub-link {
  -webkit-transform: translateY(0px);
  -ms-transform: translateY(0px);
  transform: translateY(0px);
  -webkit-transition-delay: 1s;
  -o-transition-delay: 1s;
  transition-delay: 1s;
}
.hamenu .menu-links .main-menu .sub-menu li {
  color: #eee;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 1px;
  padding: 8px 10px;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}
.hamenu .menu-links .main-menu .sub-menu li:hover {
  color: #fff;
}
.hamenu .menu-links .main-menu .sub-menu li .sub-link {
  -webkit-transform: translateY(45px);
  -ms-transform: translateY(45px);
  transform: translateY(45px);
  -webkit-transition: all 0.7s;
  -o-transition: all 0.7s;
  transition: all 0.7s;
}
.hamenu .menu-links .main-menu .sub-menu li .sub-link.back {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  cursor: pointer;
}
.hamenu .menu-links .main-menu .sub-menu li .sub-link.back i {
  margin-left: 10px;
}
.hamenu .menu-links .main-menu .sub-menu li .sub-link em {
  font-size: 13px;
  font-weight: 300;
  margin-left: 10px;
}
.hamenu .cont-info {
  color: #fff;
  margin-top: 50px;
  position: relative;
}
.hamenu .cont-info:after {
  content: "";
  width: 1px;
  height: 0;
  background: rgba(255, 255, 255, 0.1);
  position: absolute;
  left: -40px;
  top: -170px;
  -webkit-transition: all 0.7s;
  -o-transition: all 0.7s;
  transition: all 0.7s;
}
.hamenu .cont-info .item {
  margin-bottom: 30px;
  -webkit-transform: translateY(-30px);
  -ms-transform: translateY(-30px);
  transform: translateY(-30px);
  opacity: 0;
  -webkit-transition: all 0.7s;
  -o-transition: all 0.7s;
  transition: all 0.7s;
}
.hamenu .cont-info .item:last-of-type {
  margin-bottom: 0;
}
.hamenu .cont-info .item h6 {
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 10px;
}

/* ====================== [ End navbar ] ====================== */
/* ====================== [ Start Slider ] ====================== */
.slider {
  position: relative;
}
.slider.fixed-slider {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}
.slider .swiper-slide-active {
  z-index: 3;
}
.slider .parallax-slider {
  position: relative;
}
.slider .parallax-slider .swiper-slide {
  position: relative;
  overflow: hidden;
  width: 100%;
  min-height: 100vh;
  padding-bottom: 50px;
}
.slider .parallax-slider .swiper-slide .bg-img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-size: cover;
}
.slider .parallax-slider .swiper-slide-active .caption h1 {
  -webkit-transition: all 0s;
  -o-transition: all 0s;
  transition: all 0s;
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}
.slider .parallax-slider .swiper-slide-active .caption h1 .char {
  -webkit-animation: fadeInUp 0.5s cubic-bezier(0.3, 0, 0.7, 1) both;
  animation: fadeInUp 0.5s cubic-bezier(0.3, 0, 0.7, 1) both;
  -webkit-animation-delay: calc(60ms * var(--char-index));
  animation-delay: calc(60ms * var(--char-index));
}
.slider .parallax-slider .swiper-slide-active .caption .thin,
.slider .parallax-slider .swiper-slide-active .caption .btn-curve,
.slider .parallax-slider .swiper-slide-active .caption p {
  opacity: 1;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}
.slider .parallax-slider .caption .thin {
  color: #75dab4;
  font-size: 30px;
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: 8px;
  margin-bottom: 5px;
  opacity: 0;
  -webkit-transform: translateY(-20px);
  -ms-transform: translateY(-20px);
  transform: translateY(-20px);
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
  -webkit-transition-delay: 0.8s;
  -o-transition-delay: 0.8s;
  transition-delay: 0.8s;
}
.slider .parallax-slider .caption .thin span {
  color: #75dab4;
}
.slider .parallax-slider .caption h1 {
  font-size: 65px;
  font-weight: 700;
  letter-spacing: 4px;
  color: #fff;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: opacity 0.5s, visibility 0.5s;
  -o-transition: opacity 0.5s, visibility 0.5s;
  transition: opacity 0.5s, visibility 0.5s;
}
.slider .parallax-slider .caption p {
  color: #eee;
  margin-top: 15px;
  opacity: 0;
  -webkit-transform: translateY(20px);
  -ms-transform: translateY(20px);
  transform: translateY(20px);
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
  -webkit-transition-delay: 0.8s;
  -o-transition-delay: 0.8s;
  transition-delay: 0.8s;
}
.slider .parallax-slider .caption .btn-curve {
  opacity: 0;
  -webkit-transform: translateY(20px);
  -ms-transform: translateY(20px);
  transform: translateY(20px);
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
  -webkit-transition-delay: 1s;
  -o-transition-delay: 1s;
  transition-delay: 1s;
  border: 2px solid;
  line-height: 2;
}
.slider .parallax-slider .caption .btn-curve span {
  font-size: 15px;
  font-weight: 700;
  text-transform: capitalize;
}
.slider .parallax-slider .caption .btn-curve:hover span {
  color: #0c0f16;
}
.slider .parallax-slider .caption.dig h1 {
  font-weight: 800;
  font-size: 80px;
  text-transform: uppercase;
}
.slider .parallax-slider .caption.dig h1 .tline {
  margin-left: 80px;
}
.slider .parallax-slider .caption.dig h1 .whitespace {
  width: 20px;
}
.slider .setone {
  position: absolute;
  bottom: 8%;
  right: 40px;
  z-index: 8;
}
.slider .setone .swiper-nav-ctrl {
  color: #ccc;
  font-size: 13px;
  width: 55px;
  height: 55px;
  line-height: 55px;
  text-align: center;
  margin: 5px 0;
  border: 1px solid rgba(255, 255, 255, 0.3);
  position: static;
}
.slider .setone .swiper-nav-ctrl:after {
  display: none;
}
.slider .setone.setwo {
  position: static;
}
.slider .setone.setwo .swiper-nav-ctrl {
  border-radius: 50%;
}
.slider .setone.setwo .next-ctrl {
  position: absolute;
  top: 50%;
  right: 30px;
}
.slider .setone.setwo .prev-ctrl {
  position: absolute;
  top: 50%;
  left: 30px;
}
.slider .txt-botm {
  position: absolute;
  bottom: 20px;
  left: 0;
  width: 100%;
  z-index: 8;
}
.slider .txt-botm .swiper-nav-ctrl {
  position: absolute;
  width: auto;
  height: auto;
  margin-top: auto;
  top: auto;
  bottom: 0;
}
.slider .txt-botm .swiper-nav-ctrl i {
  color: #ccc;
  font-size: 13px;
  width: 45px;
  height: 45px;
  line-height: 45px;
  text-align: center;
  margin: 0;
  border-radius: 50%;
  display: inline-block;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.slider .txt-botm .swiper-nav-ctrl span {
  font-weight: 500;
  font-size: 12px;
  color: #fff;
}
.slider .txt-botm .swiper-nav-ctrl:after {
  display: none;
}
.slider .txt-botm .swiper-nav-ctrl.swiper-button-next {
  right: 40px;
}
.slider .txt-botm .swiper-nav-ctrl.swiper-button-next i {
  margin-left: 10px;
}
.slider .txt-botm .swiper-nav-ctrl.swiper-button-prev {
  left: 40px;
}
.slider .txt-botm .swiper-nav-ctrl.swiper-button-prev i {
  margin-right: 10px;
}
.slider .txt-botm .swiper-nav-ctrl:hover i {
  border: 1px solid rgba(255, 255, 255, 0.3);
}
.slider .swiper-pagination-fraction.top {
  position: absolute;
  right: 15%;
  left: auto;
  bottom: auto;
  top: 20%;
  width: auto;
  color: #fff;
  font-size: 40px;
  font-weight: 500;
}
.slider .swiper-pagination-fraction.top .swiper-pagination-current {
  font-size: 60px;
  font-weight: 700;
  position: relative;
  color: transparent;
  -webkit-text-stroke: 0.5px #fff;
}
.slider .swiper-pagination-fraction.top .swiper-pagination-current:after {
  content: "/";
  color: #fff;
  position: relative;
  margin: 0 10px;
  font-size: 12px;
  font-weight: 100;
}
.slider .swiper-pagination-fraction.top.botm {
  top: auto;
  bottom: 40px;
  right: 40px;
}
.slider .swiper-pagination-fraction.steps {
  position: absolute;
  width: 100px;
  color: #fff;
  font-size: 15px;
  bottom: 40px;
  left: calc(50% - 50px);
  right: auto;
  top: auto;
  text-align: left;
}
.slider .swiper-pagination-fraction.steps:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 15%;
  width: 70%;
  height: 1px;
  background: rgba(255, 255, 255, 0.3);
}
.slider .swiper-pagination-fraction.steps .swiper-pagination-total {
  float: right;
}
.slider .social-icon {
  position: absolute;
  left: 40px;
  bottom: 40px;
  z-index: 8;
}
.slider .social-icon a {
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 50%;
  border: 1px solid rgba(255, 255, 255, 0.05);
  color: #fff;
  font-size: 13px;
}

.cta__slider-wrapper {
  position: relative;
  width: 100%;
  height: 105vh;
  overflow: hidden;
}
.cta__slider-wrapper .block {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 0;
}

.cta__slider {
  min-height: 105vh;
}
.cta__slider .swiper-slide {
  overflow: hidden;
}

.cta__slider-arrows {
  display: block;
  position: absolute;
  bottom: 40px;
  right: 40px;
  z-index: 2;
}

.cta__slider-arrow {
  width: 55px;
  height: 55px;
  text-align: center;
  line-height: 55px;
  font-size: 13px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  display: block;
  cursor: pointer;
}
.cta__slider-arrow.cta__slider-arrow--previous {
  left: 0;
}
.cta__slider-arrow.cta__slider-arrow--next {
  left: 50%;
}

.cta__slider-item {
  position: relative;
}
.cta__slider-item .media-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}
.cta__slider-item .media-wrapper .bg-img {
  position: absolute;
  width: 70%;
  height: 100%;
  right: 0;
  top: 0;
}
.cta__slider-item .container {
  position: relative;
  z-index: 4;
}
.cta__slider-item .caption {
  position: relative;
}
.cta__slider-item .caption .top-corn,
.cta__slider-item .caption .bottom-corn {
  position: absolute;
  left: -60px;
  top: -60px;
  opacity: 0.3;
}
.cta__slider-item .caption .top-corn:after,
.cta__slider-item .caption .top-corn:before,
.cta__slider-item .caption .bottom-corn:after,
.cta__slider-item .caption .bottom-corn:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background: #75dab4;
}
.cta__slider-item .caption .top-corn:after,
.cta__slider-item .caption .bottom-corn:after {
  width: 30px;
  height: 1px;
}
.cta__slider-item .caption .top-corn:before,
.cta__slider-item .caption .bottom-corn:before {
  width: 1px;
  height: 30px;
}
.cta__slider-item .caption .bottom-corn {
  top: auto;
  bottom: -60px;
}
.cta__slider-item .caption .bottom-corn:before {
  top: auto;
  bottom: 0;
}
.cta__slider-item .caption h1 {
  font-size: 75px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 4px;
  color: #fff;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: opacity 0.5s, visibility 0.5s;
  -o-transition: opacity 0.5s, visibility 0.5s;
  transition: opacity 0.5s, visibility 0.5s;
}
.cta__slider-item .caption .thin {
  color: #75dab4;
  font-size: 20px;
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: 12px;
  margin-bottom: 5px;
  opacity: 0;
  -webkit-transform: translateY(-20px);
  -ms-transform: translateY(-20px);
  transform: translateY(-20px);
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
  -webkit-transition-delay: 0.8s;
  -o-transition-delay: 0.8s;
  transition-delay: 0.8s;
}
.cta__slider-item .caption p {
  color: #eee;
  font-weight: 300;
  font-size: 14px;
  opacity: 0;
  -webkit-transform: translateY(-20px);
  -ms-transform: translateY(-20px);
  transform: translateY(-20px);
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
  opacity: 0;
  -webkit-transform: translateY(20px);
  -ms-transform: translateY(20px);
  transform: translateY(20px);
  transition: all 0.4s;
  -webkit-transition-delay: 0.8s;
  -o-transition-delay: 0.8s;
  transition-delay: 0.8s;
}
.cta__slider-item .caption .btn-curve {
  opacity: 0;
  -webkit-transform: translateY(20px);
  -ms-transform: translateY(20px);
  transform: translateY(20px);
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
  -webkit-transition-delay: 1s;
  -o-transition-delay: 1s;
  transition-delay: 1s;
}
.cta__slider-item.swiper-slide-active .caption h1 {
  -webkit-transition: all 0s;
  -o-transition: all 0s;
  transition: all 0s;
  opacity: 1;
  visibility: visible;
}
.cta__slider-item.swiper-slide-active .caption h1 .char {
  -webkit-animation: fadeInUp 0.5s cubic-bezier(0.3, 0, 0.7, 1) both;
  animation: fadeInUp 0.5s cubic-bezier(0.3, 0, 0.7, 1) both;
  -webkit-animation-delay: calc(60ms * var(--char-index));
  animation-delay: calc(60ms * var(--char-index));
}
.cta__slider-item.swiper-slide-active .caption .btn-curve,
.cta__slider-item.swiper-slide-active .caption .thin,
.cta__slider-item.swiper-slide-active .caption p {
  opacity: 1;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}

.m50-top {
  margin-top: 50px;
}

@media only screen and (max-width: 800px) {
  .m50-top{
    margin-top: 12rem;
  }
}

.m25-left{
  margin-left: 25px;
}

.slider-bus {
  min-height: 100vh;
  position: relative;
}
.slider-bus .swiper-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.slider-bus .setone {
  position: absolute;
  bottom: 8%;
  right: 40px;
  z-index: 8;
}
.slider-bus .setone .swiper-nav-ctrl {
  color: #ccc;
  font-size: 13px;
  width: 55px;
  height: 55px;
  line-height: 55px;
  text-align: center;
  margin: 5px 0;
  border: 1px solid rgba(255, 255, 255, 0.3);
  position: static;
}
.slider-bus .setone .swiper-nav-ctrl:after {
  display: none;
}
.slider-bus .setone.setwo {
  position: static;
}
.slider-bus .setone.setwo .swiper-nav-ctrl {
  border-radius: 50%;
}
.slider-bus .setone.setwo .next-ctrl {
  position: absolute;
  top: 50%;
  right: 30px;
}
.slider-bus .setone.setwo .prev-ctrl {
  position: absolute;
  top: 50%;
  left: 30px;
}
.slider-bus .swiper-pagination-fraction {
  position: absolute;
  right: auto;
  left: 60px;
  bottom: 30px;
  width: auto;
  color: #fff;
  font-size: 40px;
  font-weight: 500;
}
.slider-bus .swiper-pagination-fraction .swiper-pagination-current {
  font-size: 60px;
  font-weight: 700;
  position: relative;
  color: transparent;
  -webkit-text-stroke: 0.5px #fff;
}
.slider-bus .swiper-pagination-fraction .swiper-pagination-current:after {
  content: "/";
  color: #fff;
  position: relative;
  margin: 0 10px;
  font-size: 12px;
  font-weight: 100;
}
.slider-bus .swiper-slide-active .caption h1 {
  -webkit-transition: all 0s;
  -o-transition: all 0s;
  transition: all 0s;
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}
.slider-bus .swiper-slide-active .caption h1 .char {
  -webkit-animation: fadeInUp 0.5s cubic-bezier(0.3, 0, 0.7, 1) both;
  animation: fadeInUp 0.5s cubic-bezier(0.3, 0, 0.7, 1) both;
  -webkit-animation-delay: calc(60ms * var(--char-index));
  animation-delay: calc(60ms * var(--char-index));
}
.slider-bus .swiper-slide-active .caption .thin,
.slider-bus .swiper-slide-active .caption .simple-btn,
.slider-bus .swiper-slide-active .caption .btn-curve,
.slider-bus .swiper-slide-active .caption p {
  opacity: 1;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}
.slider-bus .caption .thin {
  font-size: 16px;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 8px;
  margin-bottom: 5px;
  opacity: 0;
  -webkit-transform: translateY(-20px);
  -ms-transform: translateY(-20px);
  transform: translateY(-20px);
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
  -webkit-transition-delay: 0.8s;
  -o-transition-delay: 0.8s;
  transition-delay: 0.8s;
}
.slider-bus .caption h1 {
  font-size: 90px;
  font-weight: 800;
  letter-spacing: 4px;
  color: #fff;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: opacity 0.5s, visibility 0.5s;
  -o-transition: opacity 0.5s, visibility 0.5s;
  transition: opacity 0.5s, visibility 0.5s;
}
.slider-bus .caption p {
  color: #eee;
  opacity: 0;
  -webkit-transform: translateY(20px);
  -ms-transform: translateY(20px);
  transform: translateY(20px);
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
  -webkit-transition-delay: 0.8s;
  -o-transition-delay: 0.8s;
  transition-delay: 0.8s;
}
.slider-bus .caption .simple-btn,
.slider-bus .caption .btn-curve {
  opacity: 0;
  -webkit-transform: translateY(20px);
  -ms-transform: translateY(20px);
  transform: translateY(20px);
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
  -webkit-transition-delay: 1s;
  -o-transition-delay: 1s;
  transition-delay: 1s;
}
.slider-bus .ontop {
  position: relative;
  z-index: 5;
}

.curve-bg {
  position: absolute;
  top: 0;
  left: -10%;
  width: 100%;
  height: 100%;
  z-index: 3;
}

.pages-header {
  height: 75vh;
}
.pages-header .cont h1 {
  font-size: 75px;
  margin-bottom: 20px;
}
.pages-header .cont .path {
  color: #fff;
  font-size: 17px;
}
.pages-header .cont .path .active {
  color: #75dab4;
}
.pages-header .cont .path span {
  margin: 0 20px;
  font-size: 10px;
}

.page-header .cont {
  padding: 220px 0 50px;
}
.page-header .cont h6 {
  color: #ccc;
  font-weight: 400;
  font-size: 13px;
  letter-spacing: 1px;
  margin-bottom: 10px;
}
.page-header .cont h4 {
  line-height: 1.6;
}
.page-header .cont h4 .stroke {
  font-weight: 900;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: rgba(255, 255, 255, 0.7);
  color: transparent;
}

.page-header .img-wrapper {
  position: relative;
  min-height: 70vh;
}
.page-header .img-wrapper .title {
  position: absolute;
  top: 0;
  right: 0px;
  width: 100%;
  text-align: right;
  -webkit-transform: translateY(-55%);
  -ms-transform: translateY(-55%);
  transform: translateY(-55%);
  z-index: 3;
}
.page-header .img-wrapper .title h3 {
  font-size: 100px;
  font-weight: 900;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #fff;
  color: transparent;
}
.page-header .img-wrapper .map {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.page-header.proj-det.bg-img {
  min-height: 85vh;
  padding-bottom: 100px;
}
.page-header.proj-det.bg-img .item h6 {
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 4px;
  text-transform: uppercase;
  margin-bottom: 10px;
}
.page-header.proj-det.bg-img .item p {
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 12px;
}
.page-header.proj-det.bg-img .item p a {
  margin: 0 7px;
}
.page-header.proj-det.bg-img .item p a:first-of-type {
  margin-left: 0;
}
.page-header.proj-det.bg-img .item p a:last-of-type {
  margin-right: 0;
}

.page-header.proj-det .img-wrapper {
  min-height: 80vh;
}

.works-header {
  min-height: 80vh;
}
.works-header.hfixd {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}
.works-header .capt h4 {
  font-weight: 200;
  font-size: 24px;
  line-height: 1.8;
}
.works-header .capt h2 {
  font-size: 60px;
  letter-spacing: 2px;
  text-transform: uppercase;
}
.works-header .capt h2 span {
  display: block;
  font-size: 20px;
  font-weight: 300;
  text-transform: capitalize;
  color: #75dab4;
}
.works-header .capt .bactxt {
  font-size: 30vw;
  font-weight: bold;
  color: transparent;
  -webkit-text-stroke: 1px rgba(255, 255, 255, 0.1);
  z-index: -1;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  text-align: center;
}

.static {
  position: static;
}

.main-content {
  background: #0c0f16;
  position: relative;
  z-index: 3;
}

/* ====================== [ End Slider ] ====================== */
/* ====================== [ Start showcase ] ====================== */
.showcase-full .parallax-slider .swiper-slide .bg-img {
  background-position: center center;
}

.showcase-full .parallax-slider .caption h1 {
  font-size: 9vw;
  font-weight: 800;
  line-height: 1.2;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}
.showcase-full .parallax-slider .caption h1 .stroke {
  color: transparent;
  -webkit-text-stroke: 1px #fff;
}
.showcase-full .parallax-slider .caption h1 span {
  margin-left: 200px;
}

.showcase-full .parallax-slider .caption .discover {
  margin-top: 50px;
  opacity: 0;
  -webkit-transition: all 0.7s;
  -o-transition: all 0.7s;
  transition: all 0.7s;
}
.showcase-full .parallax-slider .caption .discover i {
  display: inline-block;
  width: 70px;
  height: 70px;
  line-height: 70px;
  text-align: center;
  border: 1px solid #fff;
  border-radius: 50%;
  margin-right: 10px;
}
.showcase-full .parallax-slider .caption .discover span {
  font-size: 13px;
  font-weight: 600;
}

.showcase-carus {
  height: 100vh;
}
.showcase-carus .swiper-container {
  height: 100%;
}
.showcase-carus .swiper-slide .bg-img {
  height: 60vh;
  margin: 20vh 0 0;
}
.showcase-carus .caption h1,
.showcase-carus .copy-cap h1 {
  font-size: 90px;
  font-weight: 800;
  line-height: 1.2;
  position: relative;
  left: -120px;
}
.showcase-carus .caption h1 .stroke,
.showcase-carus .copy-cap h1 .stroke {
  color: transparent;
  -webkit-text-stroke: 1px #fff;
  -webkit-transform: skewX(25deg) translateX(-100px);
  -ms-transform: skewX(25deg) translateX(-100px);
  transform: skewX(25deg) translateX(-100px);
  opacity: 0;
  -webkit-transition: opacity 0.3s, -webkit-transform 1.2s;
  transition: opacity 0.3s, -webkit-transform 1.2s;
  -o-transition: transform 1.2s, opacity 0.3s;
  transition: transform 1.2s, opacity 0.3s;
  transition: transform 1.2s, opacity 0.3s, -webkit-transform 1.2s;
  -webkit-transition-delay: 0.2s;
  -o-transition-delay: 0.2s;
  transition-delay: 0.2s;
}
.showcase-carus .caption h1 span,
.showcase-carus .copy-cap h1 span {
  margin-left: 40px;
  -webkit-transform: skewX(-25deg) translateX(100px);
  -ms-transform: skewX(-25deg) translateX(100px);
  transform: skewX(-25deg) translateX(100px);
  opacity: 0;
  -webkit-transition: opacity 0.3s, -webkit-transform 1.2s;
  transition: opacity 0.3s, -webkit-transform 1.2s;
  -o-transition: transform 1.2s, opacity 0.3s;
  transition: transform 1.2s, opacity 0.3s;
  transition: transform 1.2s, opacity 0.3s, -webkit-transform 1.2s;
  -webkit-transition-delay: 0.2s;
  -o-transition-delay: 0.2s;
  transition-delay: 0.2s;
}
.showcase-carus .caption .discover,
.showcase-carus .copy-cap .discover {
  margin-top: 50px;
  opacity: 0;
  -webkit-transition: all 0.7s;
  -o-transition: all 0.7s;
  transition: all 0.7s;
}
.showcase-carus .caption .discover i,
.showcase-carus .copy-cap .discover i {
  display: inline-block;
  width: 70px;
  height: 70px;
  line-height: 70px;
  text-align: center;
  border: 1px solid #fff;
  border-radius: 50%;
  margin-right: 10px;
}
.showcase-carus .caption .discover span,
.showcase-carus .copy-cap .discover span {
  font-size: 13px;
  font-weight: 600;
}
.showcase-carus .swiper-slide-active .caption h1 .stroke,
.showcase-carus .swiper-slide-active .copy-cap h1 .stroke {
  -webkit-transform: skewX(0) translateX(0);
  -ms-transform: skewX(0) translateX(0);
  transform: skewX(0) translateX(0);
  opacity: 1;
}
.showcase-carus .swiper-slide-active .caption h1 span,
.showcase-carus .swiper-slide-active .copy-cap h1 span {
  -webkit-transform: skewX(0) translateX(0);
  -ms-transform: skewX(0) translateX(0);
  transform: skewX(0) translateX(0);
  opacity: 1;
}
.showcase-carus .swiper-slide-active .caption .discover,
.showcase-carus .swiper-slide-active .copy-cap .discover {
  opacity: 1;
}
.showcase-carus .copy-cap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.showcase-carus .copy-cap .cap h1 span {
  color: transparent;
  -webkit-text-stroke: 1px #fff;
}
.showcase-carus .copy-cap .cap h1 .stroke {
  color: #fff;
  -webkit-text-stroke: 0;
}
.showcase-carus.circle-slide .swiper-slide {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.showcase-carus.circle-slide .swiper-slide .bg-img {
  height: 420px;
  width: 420px;
  border-radius: 50%;
  margin: auto;
  background-position: center center;
}
.showcase-carus.circle-slide .swiper-slide .bg-img:before {
  border-radius: 50%;
}
.showcase-carus.circle-slide .caption,
.showcase-carus.circle-slide .copy-cap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.showcase-carus.circle-slide .caption h1,
.showcase-carus.circle-slide .copy-cap h1 {
  font-size: 90px;
  display: block;
  min-width: 700px;
  text-align: center;
  left: -140px;
  margin: auto;
}
.showcase-carus.circle-slide .caption h1 .stroke,
.showcase-carus.circle-slide .copy-cap h1 .stroke {
  display: inline-block;
  color: transparent;
  -webkit-text-stroke: 1px #fff;
}
.showcase-carus.circle-slide .caption h1 span,
.showcase-carus.circle-slide .copy-cap h1 span {
  margin-left: auto;
  color: transparent;
  -webkit-text-stroke: 1px #fff;
}
.showcase-carus.circle-slide .copy-cap .cap h1 span {
  color: #fff;
  -webkit-text-stroke: 0;
}
.showcase-carus.circle-slide .copy-cap .cap h1 .stroke {
  color: #fff;
  -webkit-text-stroke: 0;
}
.showcase-carus.circle-slide .caption h1 .stroke,
.showcase-carus.circle-slide .copy-cap h1 .stroke {
  -webkit-transform: skewX(0) translateX(0);
  -ms-transform: skewX(0) translateX(0);
  transform: skewX(0) translateX(0);
  opacity: 1;
}
.showcase-carus.circle-slide .caption h1 span,
.showcase-carus.circle-slide .copy-cap h1 span {
  -webkit-transform: skewX(0) translateX(0);
  -ms-transform: skewX(0) translateX(0);
  transform: skewX(0) translateX(0);
  opacity: 1;
}

.showstyle {
  height: 100vh;
}
.showstyle .swiper-container {
  height: 100%;
}
.showstyle .swiper-slide .bg-img {
  height: 70vh;
  margin: 15vh 0 0;
}
.showstyle .caption h1 {
  font-size: 90px;
  font-weight: 800;
  line-height: 1.2;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}
.showstyle .caption h1 .stroke {
  color: transparent;
  -webkit-text-stroke: 1px #fff;
}
.showstyle .caption h1 span {
  margin-left: 200px;
}
.showstyle .caption .discover {
  margin-top: 50px;
  opacity: 0;
  -webkit-transition: all 0.7s;
  -o-transition: all 0.7s;
  transition: all 0.7s;
}
.showstyle .caption .discover i {
  display: inline-block;
  width: 70px;
  height: 70px;
  line-height: 70px;
  text-align: center;
  border: 1px solid #fff;
  border-radius: 50%;
  margin-right: 10px;
}
.showstyle .caption .discover span {
  font-size: 13px;
  font-weight: 600;
}

.showcase-grid {
  height: 100vh;
}
.showcase-grid .swiper-container {
  height: 100%;
}
.showcase-grid .swiper-slide .bg-img {
  height: 76vh;
  margin: 12vh 0 0;
  position: relative;
  background-position: center center;
}

.div-tooltip-tit {
  display: none;
  position: absolute;
  background-color: #000;
  padding: 0 10px;
  height: 50px;
  line-height: 50px;
  font-size: 13px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  z-index: 99999;
}

.div-tooltip-sub {
  display: none;
  position: absolute;
  background-color: #fff;
  padding: 10px;
  color: #111;
  font-size: 10px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  z-index: 99999;
}

/* ====================== [ end showcase ] ====================== */
/* ====================== [ Start Intro ] ====================== */
.about .content .co-tit {
  color: #75dab4;
  font-weight: 400;
  margin-bottom: 30px;
}

.about .img-mons .img1,
.about .img-mons .img2,
.about .img-mons .img3 {
  height: 200px;
  border-radius: 10px;
  overflow: hidden;
}
.about .img-mons .img1 img,
.about .img-mons .img2 img,
.about .img-mons .img3 img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center center;
  object-position: center center;
}

.about .img-mons .img2 {
  height: 240px;
}

.about .img-mons .img3 {
  width: 92%;
  margin-top: 20px;
}

.about .blc-img .bimg {
  -webkit-box-shadow: -10px -10px 20px -5px rgba(52, 60, 70, 0.1);
  box-shadow: -10px -10px 20px -5px rgba(52, 60, 70, 0.1);
  position: relative;
}
.about .blc-img .bimg:after {
  content: "";
  position: absolute;
  top: -30px;
  right: -20px;
  width: 100px;
  height: 150px;
  /*background-image: url(../img/dots.png);*/
  opacity: 0.4;
  z-index: -1;
}
.about .blc-img .bimg img {
  border-radius: 15px;
  overflow: hidden;
}

.about .blc-img .skills-circle {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background: #11141b;
  padding: 30px;
  border-radius: 15px;
  position: relative;
  margin-top: -80px;
  right: -50px;
  -webkit-box-shadow: 0px 10px 20px -5px rgba(52, 60, 70, 0.1);
  box-shadow: 0px 10px 20px -5px rgba(52, 60, 70, 0.1);
}
.about .blc-img .skills-circle .item:last-of-type {
  margin-left: 30px;
}

.about .ftbox ul {
  margin: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.about .ftbox ul li {
  width: 100%;
  padding: 35px 25px;
  text-align: center;
  background: #11141b;
  border-radius: 5px;
  position: relative;
}
.about .ftbox ul li.space {
  margin: 0 10px;
}
.about .ftbox ul li .icon {
  color: #75dab4;
  font-size: 30px;
  margin-bottom: 20px;
}
.about .ftbox ul li h6 {
  font-size: 16px;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 2px;
}
.about .ftbox ul li .dots span {
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: #eee;
  position: absolute;
  right: 10px;
  bottom: 12px;
  opacity: 0.1;
}
.about .ftbox ul li .dots span:first-of-type {
  bottom: 18px;
}
.about .ftbox ul li .dots span:last-of-type {
  right: 16px;
}

.hero-sec .skills-box {
  padding-left: 30px;
}
.hero-sec .skills-box .skill-item {
  margin-bottom: 80px;
}

.intro-section .htit {
  font-size: 28px;
  font-weight: 500;
}
.intro-section .htit span {
  font-size: 17px;
  font-weight: 300;
  color: #75dab4;
  margin-right: 5px;
}

.intro-section .item h6 {
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 4px;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.intro-section .item p {
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 11px;
}
.intro-section .item p a {
  margin: 0 7px;
}
.intro-section .item p a:first-of-type {
  margin-left: 0;
}
.intro-section .item p a:last-of-type {
  margin-right: 0;
}

.extra-text {
  font-size: 22px;
}

.video-wrapper {
  min-height: 500px;
}
.video-wrapper .vid {
  position: relative;
  z-index: 8;
}
.video-wrapper .vid .vid-butn:hover .icon {
  color: #fff;
}
.video-wrapper .vid .vid-butn:hover .icon:before {
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
}
.video-wrapper .vid .vid-butn .icon {
  color: #212529;
  width: 100px;
  height: 100px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  line-height: 100px;
  text-align: center;
  font-size: 17px;
  position: relative;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}
.video-wrapper .vid .vid-butn .icon:after {
  content: "";
  position: absolute;
  top: 5px;
  bottom: 5px;
  right: 5px;
  left: 5px;
  border: 1px solid #eee;
  border-radius: 50%;
  z-index: -1;
}
.video-wrapper .vid .vid-butn .icon:before {
  content: "";
  position: absolute;
  top: 5px;
  bottom: 5px;
  right: 5px;
  left: 5px;
  background: #fff;
  border-radius: 50%;
  z-index: -1;
  -webkit-transition: all 0.5s cubic-bezier(1, 0, 0, 1);
  -o-transition: all 0.5s cubic-bezier(1, 0, 0, 1);
  transition: all 0.5s cubic-bezier(1, 0, 0, 1);
}

.js-scroll__content {
  -webkit-transform-origin: top left;
  -ms-transform-origin: top left;
  transform-origin: top left;
  will-change: transform;
}

/* ====================== [ End Intro ] ====================== */
/* ====================== [ Start services ] ====================== */
.services {
  position: relative;
}
.services .container {
  position: relative;
  z-index: 2;
}
.services .item-box {
  padding: 80px 30px;
  text-align: center;
}
.services .item-box:nth-of-type(2) {
  background: #181b21;
}
.services .item-box:nth-of-type(3) {
  background: #1a1e25;
}
.services .item-box:last-of-type {
  background: #20242c;
}
.services .item-box.bg-img {
  position: relative;
  text-align: left;
  z-index: 2;
}
.services .item-box.bg-img:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #1a1e25;
  opacity: 0.8;
  z-index: -1;
}
.services .item-box .icon {
  color: #75dab4;
  font-size: 40px;
  margin-bottom: 30px;
}
.services .item-box h6 {
  line-height: 1.8;
  text-transform: capitalize;
  margin-bottom: 10px;
}
.services .rone > div {
  position: relative;
}
.services .rone > div:nth-of-type(2) {
  margin-bottom: -50px;
  margin-top: auto;
}
.services .rtwo > div {
  position: relative;
}
.services .rtwo > div:nth-of-type(2) {
  margin-bottom: -50px;
  margin-top: auto;
}
.services .mas-item {
  padding: 40px;
  margin: 50px 0;
  background: #11141b;
  position: relative;
  z-index: 2;
}
.services .mas-item:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #11141b;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
  z-index: -1;
}
.services .mas-item .bg-color {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #75dab4;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
  z-index: -2;
}
.services .mas-item:hover .bg-color {
  top: 10px;
  left: 10px;
}
.services .mas-item .icon {
  font-size: 45px;
  color: #75dab4;
  margin-bottom: 30px;
}
.services .mas-item h6 {
  font-weight: 600;
  font-size: 16px;
  line-height: 1.7;
}
.services .mas-item .more {
  margin-top: 20px;
  width: 100%;
  width: 45px;
  height: 45px;
  line-height: 45px;
  text-align: center;
  font-size: 12px;
  border-radius: 50%;
  border: 2px solid rgba(255, 255, 255, 0.05);
}
.services .step-item {
  text-align: center;
  padding: 60px;
  margin: 60px auto;
  background: #151921;
  position: relative;
}
.services .step-item.xbottom {
  bottom: -60px;
}
.services .step-item.xtop {
  top: -60px;
}
.services .step-item.xcolor {
  background: #75dab4;
  color: #11141b;
}
.services .step-item.xcolor .icon {
  color: #11141b !important;
}
.services .step-item.xcolor p {
  color: #333;
}
.services .step-item .icon {
  color: #75dab4;
  font-size: 40px;
  margin-bottom: 30px;
}
.services .step-item h6 {
  margin-bottom: 20px;
}
.services .item {
  text-align: center;
}
.services .item .icon {
  color: #75dab4;
  font-size: 40px;
  margin-bottom: 40px;
}
.services .item h6 {
  margin-bottom: 15px;
}

.min-area .img {
  padding: 120px 0 120px 80px;
  position: relative;
  z-index: 3;
}
.min-area .img:after {
  content: "";
  position: absolute;
  top: 0;
  right: 120px;
  bottom: 0;
  left: -300px;
  background: #1a1e25;
  z-index: -1;
}

.min-area .content {
  padding-left: 40px;
}
.min-area .content h4 {
  font-weight: 700;
  font-size: 40px;
  line-height: 1.5;
  margin-bottom: 20px;
}
.min-area .content ul {
  margin: 0;
}
.min-area .content ul li {
  margin-top: 25px;
  padding-left: 25px;
  position: relative;
}
.min-area .content ul li:after {
  content: "";
  width: 10px;
  height: 10px;
  border: 1px solid #999;
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 2px;
}
.min-area .content ul.feat li {
  padding: 0 0 30px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
}
.min-area .content ul.feat li:last-of-type {
  padding: 0;
  border: 0;
}
.min-area .content ul.feat li:after {
  display: none;
}
.min-area .content ul.feat li h6 {
  font-weight: 500;
  margin-bottom: 5px;
}
.min-area .content ul.feat li h6 span {
  color: #75dab4;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  font-size: 13px;
  border: 1px solid #75dab4;
  border-radius: 50%;
  margin-right: 10px;
}
.min-area .content ul.feat li p {
  font-size: 13px;
  padding-left: 45px;
}

/* ====================== [ End services ] ====================== */
/* ====================== [ Start brands ] ====================== */
.clients .hasRef:hover img {
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  opacity: 0;
}

.clients .brands:hover .link {
  opacity: 1 !important;
  -webkit-transition: all 0;
  -o-transition: all 0;
  transition: all 0;
}

.clients .brands:hover .char {
  -webkit-animation: fadeInUp 0.3s cubic-bezier(0.3, 0, 0.7, 1) both;
  animation: fadeInUp 0.3s cubic-bezier(0.3, 0, 0.7, 1) both;
  -webkit-animation-delay: calc(20ms * var(--char-index));
  animation-delay: calc(20ms * var(--char-index));
}

.clients .brands .item {
  text-align: center;
  height: auto;
}

.clients .brands .img {
  position: relative;
}
.clients .brands .img img {
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
  max-width: 100%;
  margin: auto;
  border-radius: 10px;
}
.clients .brands .img .link {
  font-size: 13px;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  position: absolute;
  top: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  opacity: 0;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  margin-top: 4.5rem;
}

.clients .bord .item {
  margin-bottom: 30px;
}

.clients-brand .brands:hover .img {
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  opacity: 0;
}

.clients-brand .brands:hover .link {
  opacity: 1 !important;
  -webkit-transition: all 0;
  -o-transition: all 0;
  transition: all 0;
}

.clients-brand .brands:hover .char {
  -webkit-animation: fadeInUp 0.3s cubic-bezier(0.3, 0, 0.7, 1) both;
  animation: fadeInUp 0.3s cubic-bezier(0.3, 0, 0.7, 1) both;
  -webkit-animation-delay: calc(20ms * var(--char-index));
  animation-delay: calc(20ms * var(--char-index));
}

.clients-brand .item {
  position: relative;
  text-align: center;
}
.clients-brand .item .link {
  font-size: 16px;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  position: absolute;
  top: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  opacity: 0;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.clients-brand .img {
  max-width: 85px;
  margin: auto;
  position: relative;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
}

/* ====================== [ End brands ] ====================== */
/* ====================== [ Start Work ] ====================== */
.portfolio {
  position: relative;
}
.portfolio .filtering .filter {
  padding: 15px;
  background: rgba(255, 255, 255, 0.02);
  border-radius: 30px;
  display: inline-block;
}
.portfolio .filtering span {
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin: 0 20px;
  cursor: pointer;
  position: relative;
}
.portfolio .filtering span.active {
  color: #75dab4;
}
.portfolio .filtering span:after {
  content: "";
  width: 7px;
  height: 7px;
  background: #75dab4;
  border-radius: 50%;
  position: absolute;
  right: -24px;
  top: 40%;
}
.portfolio .filtering span:last-of-type:after {
  display: none;
}
.portfolio .filtering.smplx .filter {
  padding: 0;
  background: transparent;
  border-radius: 0;
  display: inline-block;
}
.portfolio .filtering.smplx span {
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin: 0 20px;
  cursor: pointer;
  position: relative;
}
.portfolio .filtering.smplx span.active {
  color: #75dab4;
}
.portfolio .filtering.smplx span:after {
  content: "";
  width: 7px;
  height: 7px;
  background: #75dab4;
  border-radius: 50%;
  position: absolute;
  right: -24px;
  top: 40%;
}
.portfolio .filtering.smplx span:last-of-type:after {
  display: none;
}
.portfolio .gallery .items {
  padding: 0 50px;
  margin: 50px 0;
}
.portfolio .gallery .items.lg-mr {
  margin-top: 100px;
}
.portfolio .gallery .items .item-img {
  overflow: hidden;
  position: relative;
  -webkit-clip-path: inset(0);
  clip-path: inset(0);
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.portfolio .gallery .items .item-img > a {
  display: block;
}
.portfolio .gallery .items .item-img:hover {
  -webkit-clip-path: inset(5px 10px);
  clip-path: inset(5px 10px);
}
.portfolio .gallery .items .item-img:hover img {
  -webkit-transform: scale(1.05);
  -ms-transform: scale(1.05);
  transform: scale(1.05);
}
.portfolio .gallery .items .item-img:hover .cont {
  opacity: 1;
}
.portfolio .gallery .items .item-img img {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}
.portfolio .gallery .items .item-img .cont {
  position: absolute;
  bottom: 15px;
  left: 0;
  text-align: left;
  padding-left: 30px;
  opacity: 0;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
}
.portfolio .gallery .items .item-img .cont span {
  color: #eee;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 13px;
  font-family: "Barlow Condensed", sans-serif;
}
.portfolio .gallery .items .item-img .cont span a {
  margin: 0 10px 0 0;
}
.portfolio .gallery .items .cont {
  margin-top: 30px;
  text-align: center;
}
.portfolio .gallery .items .cont h6 {
  font-weight: 600;
  margin-bottom: 5px;
}
.portfolio .gallery .items .cont span {
  color: #999;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 400;
  font-size: 13px;
  font-family: "Barlow Condensed", sans-serif;
}
.portfolio .gallery .items .cont span a {
  margin: 0 5px;
}
.portfolio .gallery .bords .item-img {
  padding: 10px;
  border: 1px solid rgba(255, 255, 255, 0.03);
}
.portfolio .gallery .bords .cont {
  border: 1px solid rgba(255, 255, 255, 0.03);
  margin-top: 0;
  border-top: 0;
  padding: 10px;
}

.portfolio.full-bg {
  position: relative;
}
.portfolio.full-bg .cluom {
  height: 90vh;
  padding: 0;
  position: relative;
  z-index: 2;
  border-right: 1px solid rgba(255, 255, 255, 0.3);
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
}
.portfolio.full-bg .cluom.current {
  background: rgba(0, 0, 0, 0.3);
}
.portfolio.full-bg .cluom:hover .info {
  color: inherit;
}
.portfolio.full-bg .cluom:hover .info:after {
  height: 100%;
}
.portfolio.full-bg .cluom:hover .more {
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
}
.portfolio.full-bg .cluom .info {
  padding: 40px 10px 40px 20px;
  color: #fff;
  position: relative;
  z-index: 2;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
  overflow: hidden;
}
.portfolio.full-bg .cluom .info:after {
  content: "";
  position: absolute;
  top: 0;
  left: -2%;
  width: 104%;
  height: 0;
  background: #fff;
  z-index: -1;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
}
.portfolio.full-bg .cluom .info h6 {
  font-size: 11px;
  font-weight: 400;
  letter-spacing: 4px;
  text-transform: uppercase;
  margin-bottom: 10px;
}
.portfolio.full-bg .cluom .info h5 {
  font-size: 21px;
}
.portfolio.full-bg .cluom .more {
  position: absolute;
  bottom: 50px;
  right: 50px;
  color: #fff;
  font-size: 13px;
  letter-spacing: 1px;
  text-transform: uppercase;
  -webkit-transform: translateY(20px);
  -ms-transform: translateY(20px);
  transform: translateY(20px);
  opacity: 0;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
}
.portfolio.full-bg .cluom .more i {
  font-size: 10px;
  margin-left: 2px;
}
.portfolio.full-bg .glry-img,
.portfolio.full-bg .bg-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.portfolio.full-bg .glry-img {
  overflow: hidden;
}
.portfolio.full-bg .glry-img .bg-img {
  -webkit-transform: scale(1.1, 1.1);
  -ms-transform: scale(1.1, 1.1);
  transform: scale(1.1, 1.1);
  opacity: 0;
  -webkit-transition: all 0.7s;
  -o-transition: all 0.7s;
  transition: all 0.7s;
  background-size: cover;
  background-position: center center;
}
.portfolio.full-bg .glry-img .bg-img.current {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  opacity: 1;
}

.work-carousel .swiper-slide {
  padding: 0px 15px;
}

.work-carousel .swiper-slide-active .noraidus .cont {
  opacity: 1;
  visibility: visible;
  -webkit-transition-delay: 0.5s;
  -o-transition-delay: 0.5s;
  transition-delay: 0.5s;
}

.work-carousel .next-ctrl,
.work-carousel .prev-ctrl {
  color: #000;
  width: 60px;
  height: 60px;
  border-radius: 0;
  border: 0;
  background: #fff;
  opacity: 0;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.work-carousel .next-ctrl:after,
.work-carousel .prev-ctrl:after {
  display: none;
}

.work-carousel .simp-next,
.work-carousel .simp-prev {
  color: #fff;
  top: auto;
  bottom: 0;
}
.work-carousel .simp-next:after,
.work-carousel .simp-prev:after {
  display: none;
}

.work-carousel .simp-next {
  right: 30px;
}

.work-carousel .simp-prev {
  left: 30px;
}

.work-carousel:hover .next-ctrl,
.work-carousel:hover .prev-ctrl {
  opacity: 1;
}

.work-carousel .content {
  position: relative;
}
.work-carousel .content .item-img {
  position: relative;
  height: 480px;
  line-height: 0;
  border-radius: 10px 10px 0 0;
}
.work-carousel .content:hover .item-ig {
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
}
.work-carousel .content .cont {
  position: relative;
}
.work-carousel .content .cont h6 {
  color: #75dab4;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-bottom: 10px;
}
.work-carousel .content .cont h4 {
  font-size: 17px;
}
.work-carousel .content .cont .icon {
  width: 35px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  background: rgba(255, 255, 255, 0.03);
  border-radius: 50%;
  font-size: 12px;
  position: absolute;
  top: calc(50% - 17px);
  right: 15px;
}
.work-carousel .content .bgbox {
  background: #13161d;
  padding: 20px 20px 20px 30px;
  border-radius: 0 0 10px 10px;
}

.work-carousel.caroul .content .item-img {
  height: 400px;
}

.work-carousel .noraidus .item-img {
  height: 480px;
  border-radius: 0;
}

.work-carousel .noraidus .cont {
  position: relative;
  text-align: center;
  padding-top: 30px;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
}
.work-carousel .noraidus .cont h6 {
  color: #75dab4;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-bottom: 10px;
}
.work-carousel .noraidus .cont h4 {
  font-size: 17px;
}

.half-bg {
  position: absolute;
  left: 0;
  width: 100%;
  height: 200px;
  background: #11141b;
  z-index: 1;
}
.half-bg.bottom {
  bottom: -2rem;
}
.half-bg.top {
  top: 0;
}
.half-bg.not-sub {
  background: #0c0f16;
}

.works .lg-space > div {
  padding: 0 50px;
  margin-bottom: 50px;
}

.next-prog .box {
  position: relative;
}
.next-prog .box .bg-img {
  height: 320px;
  width: 320px;
  border-radius: 50%;
  margin: auto;
  background-position: center center;
  position: relative;
}
.next-prog .box .bg-img:before {
  border-radius: 50%;
}
.next-prog .box .caption,
.next-prog .box .copy-cap {
  position: absolute;
  top: 0;
  left: 50%;
  height: 100%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}
.next-prog .box .caption h1,
.next-prog .box .copy-cap h1 {
  font-size: 55px;
  font-weight: 800;
  display: block;
  min-width: 700px;
  text-align: center;
  margin: auto;
}
.next-prog .box .caption h1 .stroke,
.next-prog .box .copy-cap h1 .stroke {
  display: inline-block;
  color: transparent;
  -webkit-text-stroke: 1px #fff;
}
.next-prog .box .copy-cap {
  z-index: -1;
}

/* ====================== [ End Work ] ====================== */
/* ====================== [ Start services ] ====================== */
.process .coulmn {
  position: relative;
}
.process .coulmn:after {
  position: absolute;
  content: "";
  width: 75px;
  border-top: 2px dashed #75dab4;
  top: 75px;
  right: -35px;
}

.process .item {
  text-align: center;
}
.process .item .bg-img {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  margin: 0 auto 30px;
  text-align: center;
  position: relative;
}
.process .item .bg-img:after {
  content: "";
  position: absolute;
  top: 7px;
  left: 7px;
  right: 7px;
  bottom: 7px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.25);
}
.process .item .bg-img h3 {
  color: #fff;
  font-size: 40px;
  font-weight: 300;
  line-height: 150px;
  position: relative;
  z-index: 3;
}

/* ====================== [ End services ] ====================== */
/* ====================== [ Start progress bar ] ====================== */
.skills-circle .item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.skills-circle .skill {
  position: relative;
}
.skills-circle .skill span {
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateX(-50px) translateY(-50px);
  -ms-transform: translateX(-50px) translateY(-50px);
  transform: translateX(-50px) translateY(-50px);
  font-size: 20px;
  font-weight: 300;
}

.skills-circle .cont {
  padding-top: 25px;
  padding-left: 25px;
}
.skills-circle .cont span {
  font-size: 12px;
  font-weight: 300;
  opacity: 0.9;
  margin-bottom: 10px;
}
.skills-circle .cont h6 {
  font-size: 15px;
  font-weight: 500;
}

.skills-box .skill-item {
  margin-bottom: 50px;
}
.skills-box .skill-item:last-of-type {
  margin-bottom: 0;
}

.skills-box h6 {
  font-size: 13px;
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-bottom: 10px;
}

.skills-box .skill-progress {
  height: 7px;
  background-color: rgba(255, 255, 255, 0.05);
  position: relative;
}
.skills-box .skill-progress .progres {
  position: absolute;
  height: 100%;
  width: 0;
  top: 0;
  left: 0;
  background-color: #75dab4;
  -webkit-transition: all 1.5s;
  -o-transition: all 1.5s;
  transition: all 1.5s;
}
.skills-box .skill-progress .progres:after {
  content: attr(data-value);
  position: absolute;
  right: 10px;
  top: -45px;
  padding: 7px 15px;
  border-radius: 5px;
  background: #75dab4;
  color: #111;
  font-size: 12px;
  font-weight: 600;
}
.skills-box .skill-progress .progres:before {
  content: "";
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 8px solid #75dab4;
  position: absolute;
  right: 15px;
  bottom: 14px;
}

/* ====================== [ End progress bar ] ====================== */
/* ====================== [ Start tabs & list ] ====================== */
.smp-list {
  margin: 0;
}
.smp-list li {
  margin-top: 20px;
  font-size: 15px;
  font-weight: 300;
  padding-left: 20px;
  position: relative;
}
.smp-list li:after {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  width: 5px;
  height: 1px;
  background: #75dab4;
}

/* ====================== [ Start tabs & list ] ====================== */
/* ====================== [ Start Team ] ====================== */
.team {
  position: relative;
}
.team .team-container {
  width: 140%;
  position: relative;
}
.team .team-container:after {
  content: "";
  position: absolute;
  top: -120px;
  bottom: -120px;
  right: 0;
  width: 80%;
  background: #1a1e25;
  z-index: -1;
}
.team .item {
  padding: 0 15px;
}
.team .item .info {
  padding: 15px;
  background: #11141b;
  text-align: center;
  position: relative;
  overflow: hidden;
}
.team .item .info h5 {
  font-size: 18px;
  margin-bottom: 2px;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.team .item .info span {
  color: #999;
  font-size: 14px;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.team .item .info .social {
  position: absolute;
  bottom: -300px;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 30px 5px 0;
  z-index: 2;
  opacity: 0;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
}
.team .item .info .social a {
  font-size: 13px;
  margin: 0 8px;
  color: #75dab4;
}
.team .item:hover .info h5,
.team .item:hover .info span {
  opacity: 0;
}
.team .item:hover .info .social {
  bottom: 0;
  opacity: 1;
}
.team .navs .prev,
.team .navs .next {
  color: #ccc;
  font-size: 13px;
  width: 55px;
  height: 55px;
  line-height: 55px;
  text-align: center;
  border-radius: 50%;
  border: 1px solid rgba(255, 255, 255, 0.3);
  position: static;
}
.team .slick-dots {
  bottom: -40px;
}


.team .slick-dots li.slick-active button {
  background: #75dab4;
}
.team .slick-dots li button {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  opacity: 0.8;
}
.team .slick-dots li button:before {
  content: "";
  width: 4px;
  height: 4px;
  border-radius: 50%;
  position: absolute;
  top: 4px;
  left: 4px;
  line-height: 1;
  background: #75dab4;
  opacity: 1;
}

.team-crv .img-box .img {
  min-width: 150px !important;
}
.team-crv .img-box .img.sizxl {
  width: 90%;
}
.team-crv .img-box .img.sizmd {
  width: 65%;
}
.team-crv .img-box .img.sizsm {
  width: 50%;
}

.team-crv .img-box .toright .img {
  margin-left: auto;
}

.team-crv .img-box .toleft .img {
  margin-right: auto;
}

/* ====================== [ End Team ] ====================== */
/* ====================== [ Start Testimonials ] ====================== */
.testimonials .item {
  text-align: center;
  margin: 0 15px;
}
.testimonials .item p {
  font-size: 22px;
  font-weight: 300;
  padding: 10px;
}
.testimonials .item .info {
  margin-top: 30px;
}
.testimonials .item .info .img {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  overflow: hidden;
  margin: 0 auto 20px;
}
.testimonials .item .info .author-name {
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 500;
  margin-bottom: 5px;
  color: #75dab4;
}
.testimonials .item .info .author-details {
  font-size: 13px;
}

.testimonials .slick-dots {
  bottom: -30px;
}

.slick-prev,
.slick-next{
  display: none!important;
}

.testimonials .slick-dots li {
  width: 7px;
  height: 7px;
  border-radius: 50%;
  border: 1px solid #eee;
}
.testimonials .slick-dots li.slick-active {
  background: #eee;
}
.testimonials .slick-dots li button {
  display: none;
}

.testimonials .arrows .next,
.testimonials .arrows .prev {
  position: absolute;
  right: -50px;
  top: 50%;
  width: 50px;
  height: 50px;
  line-height: 55px;
  text-align: center;
  font-size: 20px;
  border: 1px solid #eee;
  border-radius: 50%;
  opacity: 0;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
}
.testimonials .arrows .next:hover,
.testimonials .arrows .prev:hover {
  border-color: var(--color-gold);
}

.testimonials .arrows .prev {
  right: auto;
  left: -50px;
}

.testimonials:hover .arrows .next,
.testimonials:hover .arrows .prev {
  opacity: 1;
  right: 0;
}

.testimonials:hover .arrows .prev {
  left: 0;
}

.testimonials.lftstl .item {
  text-align: left;
}
.testimonials.lftstl .item .info .author .lxleft {
  display: table-cell;
  vertical-align: middle;
}
.testimonials.lftstl .item .info .author .lxleft .img {
  margin: 0;
}
.testimonials.lftstl .item .info .author .fxright {
  display: table-cell;
  padding: 20px;
}

.testimonials.lftstl .slick-dots {
  text-align: right;
}

.block-sec .video-box .img {
  position: relative;
}
.block-sec .video-box .img:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #11141b;
  opacity: 0.4;
}

.block-sec .vid-area .vid-icon .vid {
  width: 80px;
  height: 80px;
  background: #fff;
  border-radius: 50%;
  text-align: center;
  line-height: 80px;
  position: relative;
}
.block-sec .vid-area .vid-icon .vid:after {
  content: "";
  position: absolute;
  top: -10px;
  left: -10px;
  right: -10px;
  bottom: -10px;
  background: #fff;
  border-radius: 50%;
  opacity: 0.1;
}
.block-sec .vid-area .vid-icon .vid span {
  font-size: 30px;
  color: #75dab4;
}

.block-sec .vid-area .cont {
  margin-top: 40px;
}
.block-sec .vid-area .cont h6 {
  text-transform: uppercase;
  font-weight: 300;
  margin-bottom: 15px;
}
.block-sec .vid-area .cont h3 {
  font-size: 45px;
  font-weight: 700;
}

.block-sec .testim-box {
  position: relative;
  background: #75dab4;
  padding: 80px 40px;
  margin-bottom: -120px;
}
.block-sec .testim-box .head-box h6 {
  color: #0c0f16;
  font-size: 12px;
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: 4px;
  margin-bottom: 10px;
}
.block-sec .testim-box .head-box h4 {
  color: #0c0f16;
  margin-bottom: 40px;
}
.block-sec .testim-box .item p {
  color: #333;
  font-size: 17px;
  margin-bottom: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}
.block-sec .testim-box .item .info .img {
  display: table-cell;
  vertical-align: middle;
}
.block-sec .testim-box .item .info .img .img-box {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  overflow: hidden;
}
.block-sec .testim-box .item .info .cont {
  display: table-cell;
  vertical-align: middle;
  padding-left: 15px;
}
.block-sec .testim-box .item .info .cont .author-name {
  color: #0c0f16;
  font-size: 16px;
  margin-bottom: 5px;
  letter-spacing: 2px;
  text-transform: uppercase;
}
.block-sec .testim-box .item .info .cont .author-details {
  color: #0c0f16;
  font-size: 13px;
  font-weight: 300;
  text-transform: uppercase;
}
.block-sec .testim-box .slick-dots {
  text-align: right;
}
.block-sec .testim-box .slick-dots li {
  width: 7px;
  height: 7px;
  border-radius: 50%;
  border: 1px solid #0c0f16;
}
.block-sec .testim-box .slick-dots li.slick-active {
  background: #0c0f16;
}
.block-sec .testim-box .slick-dots li button {
  display: none;
}

.block-sec .showreel .video-box {
  margin-top: 200px;
  position: relative;
  margin-bottom: -100px;
  z-index: 2;
}
.block-sec .showreel .video-box:after,
.block-sec .showreel .video-box:before {
  content: "";
  position: absolute;
  width: 20%;
  height: 100%;
  top: 0;
  border-right: 1px solid #eee;
  border-left: 1px solid #eee;
  opacity: 0.2;
  z-index: 3;
}
.block-sec .showreel .video-box:after {
  left: 20%;
}
.block-sec .showreel .video-box:before {
  right: 20%;
}
.block-sec .showreel .video-box .tit-text {
  position: absolute;
  top: -100px;
  width: 100%;
  text-align: center;
  z-index: -1;
}
.block-sec .showreel .video-box .tit-text h3 {
  font-size: 100px;
  font-weight: 700;
  text-transform: uppercase;
  color: transparent;
  -webkit-text-stroke: 1px rgba(255, 255, 255, 0.1);
}
.block-sec .showreel .video-box .vid {
  width: 140px;
  height: 140px;
  line-height: 140px;
  text-align: center;
  font-size: 20px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.15);
  border: 1px solid #fff;
  position: absolute;
  top: calc(50% - 70px);
  left: calc(50% - 70px);
  z-index: 4;
}
.block-sec .showreel .video-box .img {
  -webkit-box-shadow: 0px 10px 30px -5px rgba(52, 60, 70, 0.5);
  box-shadow: 0px 10px 30px -5px rgba(52, 60, 70, 0.5);
  border-radius: 5px;
  overflow: hidden;
}

.clients-brand .item a {
  width: 80px;
  opacity: 0.5;
}
.clients-brand .item a:hover {
  opacity: 1;
}

/* ====================== [ End Testimonials ] ====================== */
/* ====================== [ Start Blog ] ====================== */
.blog-curs .item-box {
  padding: 0 15px;
}

.blog-curs .item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.blog-curs .item .bimg {
  width: 50%;
}
.blog-curs .item .bimg .img {
  min-height: 320px;
  background-position: right;
  background-size: cover;
}
.blog-curs .item .cont {
  width: 50%;
  padding: 10px 30px;
  background: #151921;
}
.blog-curs .item .cont .info {
  margin-bottom: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.blog-curs .item .cont .info .author {
  color: #75dab4;
}
.blog-curs .item .cont .info .date {
  margin-left: auto;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 2px;
}
.blog-curs .item .cont .info .date i {
  font-size: 20px;
  font-weight: 200;
  font-style: normal;
}
.blog-curs .item .cont h6 {
  line-height: 1.5;
  font-size: 22px;
  margin-bottom: 30px;
}

.blog-grid .item {
  padding: 40px;
  position: relative;
}
.blog-grid .item:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #13161d;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
}
.blog-grid .item.active:after {
  opacity: 0.85;
}
.blog-grid .item:hover:after {
  opacity: 0.7;
}
.blog-grid .item .cont {
  position: relative;
  z-index: 2;
}
.blog-grid .item .cont .date {
  text-transform: uppercase;
  margin-bottom: 40px;
}
.blog-grid .item .cont .date i {
  font-size: 30px;
  font-style: normal;
  display: block;
}
.blog-grid .item .cont .info {
  margin-bottom: 20px;
}
.blog-grid .item .cont .info .author {
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-right: 10px;
}
.blog-grid .item .cont .info .tag {
  color: var(--color-post-topics);
  margin-left: 10px;
}
.blog-grid .item .cont h6 {
  line-height: 1.7;
  margin-bottom: 40px;
}
.blog-grid .item.list {
  padding: 0;
  border: var(--color-blog-card-border);
  border-style: solid;
}
.blog-grid .item.list:after {
  display: none;
}
.blog-grid .item.list .cont {
  background: #282828;
  padding: 30px;
  position: relative;
}
.blog-grid .item.list .cont .date {
  position: absolute;
  top: -15px;
  right: 15px;
  padding: 5px 15px;
  background: var(--color-post-date-bg);
  color: #222;
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  z-index: 2;
}
.blog-grid .item.list .cont .date i {
  font-size: 20px;
}
.blog-grid .item.list .cont .date:after {
  content: "";
  position: absolute;
  top: -5px;
  left: -5px;
  right: -5px;
  bottom: -5px;
  border: 5px solid rgba(255, 255, 255, 0.1);
}

.blog-pg .posts .item .img {
  position: relative;
}

.blog-pg .posts .item .content {
  padding-top: 25px;
}
.blog-pg .posts .item .content .date {
  margin-right: 15px;
  text-transform: uppercase;
  font-size: 12px;
}
.blog-pg .posts .item .content .date .num {
  font-size: 12px;
}
.blog-pg .posts .item .content .tags {
  margin-bottom: 10px;
  display: inline-block;
}
.blog-pg .posts .item .content .tags a {
  font-size: 12px;
  font-weight: 500;
  color: #75dab4;
  padding-right: 20px;
  position: relative;
}
.blog-pg .posts .item .content .tags a:after {
  content: "";
  width: 8px;
  height: 1px;
  background: #eee;
  position: absolute;
  right: 4px;
  top: 50%;
}
.blog-pg .posts .item .content .tags a:last-of-type {
  padding-right: 0;
}
.blog-pg .posts .item .content .tags a:last-of-type:after {
  display: none;
}
.blog-pg .posts .item .content h4 {
  line-height: 1.6;
  margin-bottom: 5px;
}

.blog-pg .posts .pagination {
  margin: 80px auto 0;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  text-align: center;
}
.blog-pg .posts .pagination span {
  text-align: center;
  margin: 0 2px;
}
.blog-pg .posts .pagination span a {
  width: 50px;
  height: 50px;
  line-height: 50px;
  border: 1px solid #eee;
  font-size: 18px;
}
.blog-pg .posts .pagination span.active a {
  border-color: #75dab4;
  color: #75dab4;
}

.blog-pg.single .post .main-img .simpleParallax {
  height: 70vh;
  max-height: 600px;
}

.blog-pg.single .post .main-img img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center center;
  object-position: center center;
}

.blog-pg.single .post .content p {
  font-size: 16px;
  font-family: inherit;
  margin: 30px 0;
}

.blog-pg.single .post .content ul li {
  margin: 10px 10px 0;
  list-style-type: circle;
  padding: 0 5px 0;
}
.blog-pg.single .post .content ul li span {
  margin-right: 10px;
  font-weight: 500;
  font-size: 13px;
}

.blog-pg.single .post .content .quotes {
  background: #151921;
  padding: 30px;
  margin: 30px 0;
}
.blog-pg.single .post .content .quotes p {
  font-size: 18px;
  font-style: italic;
}

.blog-pg.single .post .content .spacial p {
  font-size: 18px;
  font-weight: 500;
}

.blog-pg.single .post .content .share-info {
  margin-top: 20px;
  padding-top: 10px;
  border-top: 1px solid #999;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.blog-pg.single .post .content .share-info .social a {
  font-size: 13px;
  margin-right: 20px;
}
.blog-pg.single .post .content .share-info .tags {
  margin-left: auto;
}
.blog-pg.single .post .content .share-info .tags a {
  font-size: 12px;
  margin: 0 10px;
}

.blog-pg.single .post .author {
  padding: 30px;
  margin-top: 40px;
  background: #151921;
}
.blog-pg.single .post .author .author-img {
  display: table-cell;
  vertical-align: middle;
  width: 140px;
}
.blog-pg.single .post .author .info {
  display: table-cell;
  padding-left: 40px;
}
.blog-pg.single .post .author .info h6 {
  margin-bottom: 10px;
  font-size: 15px;
}
.blog-pg.single .post .author .info h6 span {
  text-transform: uppercase;
  margin-right: 10px;
}
.blog-pg.single .post .author .info p {
  padding: 0;
  margin: 0;
}
.blog-pg.single .post .author .info .social {
  margin-top: 15px;
  font-size: 13px;
}
.blog-pg.single .post .author .info .social a {
  margin-right: 15px;
}

.blog-pg.single .pagination {
  margin-top: 80px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.blog-pg.single .pagination span {
  width: 100%;
  padding: 20px;
  border-right: 1px solid rgba(255, 255, 255, 0.1);
  border-left: 1px solid rgba(255, 255, 255, 0.1);
  text-transform: uppercase;
  font-weight: 500;
  font-size: 13px;
  letter-spacing: 1px;
}
.blog-pg.single .pagination .icon {
  border: 0;
  font-size: large;
  width: 70px;
}

.blog-pg.single .comments-area {
  margin-top: 80px;
}
.blog-pg.single .comments-area .item {
  padding: 30px 0;
  margin: 30px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
.blog-pg.single .comments-area .item.relped {
  margin-left: 100px;
}
.blog-pg.single .comments-area .item .comment-img {
  display: table-cell;
  width: 80px;
}
.blog-pg.single .comments-area .item .info {
  display: table-cell;
  vertical-align: middle;
  padding-left: 40px;
  position: relative;
}
.blog-pg.single .comments-area .item .info h6 {
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 500;
}
.blog-pg.single .comments-area .item .info h6 span {
  display: block;
  color: #75dab4;
  font-size: 12px;
  font-weight: 400;
  margin-top: 5px;
}
.blog-pg.single .comments-area .item .info p {
  line-height: 1.5;
  margin-top: 15px;
}
.blog-pg.single .comments-area .item .info .replay {
  position: absolute;
  right: 0;
  top: 0;
  font-size: 13px;
}
.blog-pg.single .comments-area .item .info .replay i {
  margin-left: 5px;
}

.blog-pg.single .comment-form {
  margin-top: 80px;
}
.blog-pg.single .comment-form form {
  margin-top: 50px;
}
.blog-pg.single .comment-form form input,
.blog-pg.single .comment-form form textarea {
  color: #fff;
  width: 100%;
  padding: 17px;
  background: #13161d;
  border: 0;
}
.blog-pg.single .comment-form form textarea {
  height: 140px;
}
.blog-pg.single .comment-form form button {
  cursor: pointer;
}

.next-project {
  position: relative;
  padding: 80px 0;
  margin-top: 90px;
  background: #fafafa;
  z-index: 2;
  overflow: hidden;
}
.next-project:before {
  display: none;
}
.next-project:after {
  content: "";
  height: 0;
  width: 100%;
  position: absolute;
  bottom: auto;
  right: 0;
  top: 0;
  -webkit-transition: height 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
  -o-transition: height 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
  transition: height 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
  background: #f5f5f5;
  z-index: -1;
}
.next-project:hover:after {
  height: 100%;
  bottom: 0;
  top: auto;
}
.next-project .content {
  width: 100%;
}
.next-project .content h2 {
  font-size: 90px;
  font-weight: 800;
  text-transform: uppercase;
  letter-spacing: 4px;
  margin-bottom: 20px;
}
.next-project .content p {
  font-size: 18px;
  color: #555;
}

/* ====================== [ End Blog ] ====================== */
/* ====================== [ Start Call To Action  ] ====================== */
.call-action .content h6 {
  color: #75dab4;
  font-size: 0.8rem;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 4px;
  margin-bottom: 10px;
}
.call-action .content h6 span {
  color: #75dab4;
}

.call-action .content h2 {
  font-weight: 700;
  font-size: 4vw;
  line-height: 1.2;
  text-transform: uppercase;
  margin-bottom: 20px;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #fff;
  color: white;
}

.call-action .content h2 b span {
  color: #fff;
  -webkit-text-stroke-width: 0;
}

.call-action .content p {

  font-weight: 300;
}

.call-action .btn-curve {
  border: 2px solid;
  line-height: 2;
}
.call-action .btn-curve span {
  font-size: 15px;
  font-weight: 700;
  text-transform: none;
}
.call-action .btn-curve:hover span {
  color: #ffd700;
}

.inverted:hover span {
  color: #ffd700;
}

.call-action .tlink {
  margin-left: auto;
  border-bottom: 1px solid #eee;
}

.call-action.next {
  position: relative;
  z-index: 2;
  overflow: hidden;
}
.call-action.next:after {
  display: none;
}
.btn-boder{
  border: 1px;
  border-style: solid;
}
.call-action.next:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: block;
  background: #0a0a0a;
  opacity: 1;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}
.call-action.next .content h2 {
  font-size: 5vw;
  font-weight: 700;
}
.call-action.next .content h2 b {
  font-weight: 700;
}
.call-action.next .nxt-img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-transform: scale(1.4);
  -ms-transform: scale(1.4);
  transform: scale(1.4);
  background-size: cover;
  background-position: center;
  z-index: -1;
  -webkit-transition: all 1s linear;
  -o-transition: all 1s linear;
  transition: all 1s linear;
}
.call-action.next:hover:before {
  opacity: 0.9;
}
.call-action.next:hover .nxt-img {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  -webkit-transition: all 10s linear;
  -o-transition: all 10s linear;
  transition: all 10s linear;
}

/* ====================== [ End Call To Action  ] ====================== */
/* ====================== [ Start Numbers & Counter  ] ====================== */
.number-sec .item {
  text-align: center;
  position: relative;
  z-index: 2;
}
.number-sec .item:after {
  content: "";
  width: 1px;
  height: 100%;
  background: #fff;
  position: absolute;
  bottom: 0;
  left: 0;
  opacity: 0.05;
}
.number-sec .item.no-bord:after {
  display: none;
}
.number-sec .item .icon {
  position: absolute;
  left: 0;
  bottom: 0;
  font-size: 70px;
  width: 100%;
  opacity: 0.05;
  z-index: -1;
}
.number-sec .item h3 {
  font-weight: 200;
  letter-spacing: 2px;
  margin-bottom: 15px;
}
.number-sec .item p {
  color: #fff;
  letter-spacing: 1px;
}

/* ====================== [ End Numbers & Counter  ] ====================== */
/* ====================== [ Start contact ] ====================== */
.contact-sec .form .form-group {
  margin-bottom: 30px;
}
.contact-sec .form .form-group p,
.contact-sec .form .react-datepicker__input-container input {
  color: #b5b5b5fc !important;
  font-weight: 400;
  font-size: 16px;
}

.contact-sec .form .react-datepicker__input-container input::placeholder {
  color: #b5b5b5fc !important;
  font-weight: 400;
  font-size: 16px;
}

.contact-sec .form .form-group label{
  color: #bbbbbbfc;
}
.contact-sec .form .form-group input,
.contact-sec .form .form-group textarea
{
  width: 100%;
  padding: 15px 0;
  border: 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  background: transparent;
  color: #c9c9c9;
}
.contact-sec .form .form-group textarea::placeholder
{
  width: 100%;
  padding: 15px 0;
  border: 0;
 
  background: transparent;
  color: #cdcdcd;
}
.greenbtn {
  background-color: #439b17 !important;
  font-family: Lato-regular;
  padding: 10px;
}

.contact-sec .form .form-group input::placeholder
{
  width: 100%;
  padding: 15px 0;
  border: 0;
  background: transparent;
  color: #c9c9c9;
}

.contact-area label{
  color: #c9c9c9;
}



.contact-sec .form .form-group input[type="radio"]{
  width: 30px;
  align-items: center;
}

.contact-sec .form .form-group input[type="radio"]{
  width: 30px;
  align-items: center;
}

.contact-sec .form .form-group input[type="radio"] {
  color: #fff;
}

.contact-sec .form button,
a.greenbtn{
  color: #fff;
  font-size: 17px;
  background: transparent;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;
  border-top-color: rgb(118, 118, 118);
  border-bottom-color: rgb(118, 118, 118);
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-bottom-style: outset;
  border-bottom-width :1.81818px;
  border-image-outset :0;
  border-image-repeat :stretch;
  border-image-slice: 100%;
  border-image-source: none;
  border-image-width: 1;
  border-left-color:rgb(118, 118, 118);
  border-left-style :outset;
  border-left-width :1.81818px;
  border-right-color :rgb(118, 118, 118);
  border-right-style: outset;
  border-right-width :1.81818px;
  border-top-color :rgb(118, 118);
  border-top-left-radius :0px;
  border-top-right-radius :0px;
  border-top-style: outset;
  border-top-width :1.81818px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 8px 15px 0px;
  box-sizing:border-box;
}

a:hover.greenbtn{
  background-color: #2EE59D;
      box-shadow: 0px 15px 20px rgba(46, 229, 157, 0.4);
      color: #fff;
      transform: translateY(-7px);
}

.contact-sec .form button:hover {
  background-color: #2EE59D;
  box-shadow: 0px 15px 20px rgba(46, 229, 157, 0.4);
  color: #fff;
  transform: translateY(-7px);
}

.contact .form input,
.contact .form textarea {
  color: #fff;
  width: 100%;
  padding: 15px 0;
  background: transparent;
  border: 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

.contact .form textarea {
  padding: 15px;
  min-height: 140px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  margin-top: 30px;
}

.contact .cont-info h3 {
  font-size: 18px;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 4px;
  margin-bottom: 10px;
}

.contact .cont-info .item h5 {
  font-size: 16px;
  font-weight: 400;
  color: #999;
  margin: 10px 0;
}

.contact .cont-info .item h6 {
  font-size: 14px;
  font-weight: 300;
  color: #999;
  line-height: 1.7;
}

.contact .cont-info .social a {
  width: 45px;
  height: 45px;
  line-height: 45px;
  text-align: center;
  background: rgba(255, 255, 255, 0.01);
  border-radius: 50%;
  margin-right: 5px;
  font-size: 13px;
}

.map {
  width: 100%;
  height: 60vh;
}

/* ====================== [ End contact ] ====================== */
/* ====================== [ Start Footer ] ====================== */
.footer-half {
  position: relative;
  z-index: 2;
}
.footer-half:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 50%;
  background: #222222;
  z-index: -1;
}
.footer-half .stit {
  display: inline-block;
  text-transform: uppercase;
  letter-spacing: 4px;
  font-size: 13px;
  font-weight: 300;
  margin-bottom: 5px;
}
.footer-half .cont .logo {
  width: 55px;
  margin-bottom: 30px;
}
.footer-half .cont .con-info li {
  margin-top: 10px;
  font-weight: 200;
}
.footer-half .cont .con-info li span {
  margin-right: 10px;
  font-weight: 500;
}
.footer-half .cont .social-icon {
  margin-top: 10px;
}
.footer-half .cont .social-icon .social a {
  width: 45px;
  height: 45px;
  line-height: 45px;
  text-align: center;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  margin-right: 5px;
  font-size: 22px;

}
.footer-half .subscribe p {
  color: #999;
  font-size: 12px;
  font-weight: 300;
  margin-bottom: 10px;
}
.footer-half .subscribe form .form-group {
  position: relative;
  margin: 0;
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.footer-half .subscribe form input {
  padding: 15px;
  width: 100%;
  background: rgba(255, 255, 255, 0.01);
  border: 0;
  color: #fff;
}
.footer-half .subscribe form button {
  border: 0;
  padding: 15px 30px;
  background: rgba(255, 255, 255, 0.02);
  color: #75dab4;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 13px;
  z-index: 2;
  cursor: pointer;
}
.footer-half .insta .insta-gallary a {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 5px;
}
.footer-half .insta .insta-gallary a img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center center;
  object-position: center center;
}
.footer-half .copyrights {
  margin-top: 10px;
  padding: 30px 0;
  background: #242424;
}
.footer-half .copyrights p {
  font-size: 12px;
  color: #999;
}
.footer-half .copyrights p a {
  color: var(--color-rose);
}

/* ====================== [ End Footer ] ====================== */
/* ====================== [ Start Responsive ] ====================== */
@media screen and (max-width: 991px) {
  .md-mb10 {
    margin-bottom: 10px;
  }
  .md-mb20 {
    margin-bottom: 20px;
  }
  .md-mb30 {
    margin-bottom: 30px;
  }
  .md-mb40 {
    margin-bottom: 40px;
  }
  .md-mb50 {
    margin-bottom: 50px;
  }
  .md-mb60 {
    margin-bottom: 60px;
  }
  .md-mb70 {
    margin-bottom: 70px;
  }
  .md-mb80 {
    margin-bottom: 80px;
  }
  .md-pb10 {
    padding-bottom: 10px;
  }
  .md-pb20 {
    padding-bottom: 20px;
  }
  .md-pb30 {
    padding-bottom: 30px;
  }
  .md-pb40 {
    padding-bottom: 40px;
  }
  .md-pb50 {
    padding-bottom: 50px;
  }
  .md-pb60 {
    padding-bottom: 60px;
  }
  .md-pb70 {
    padding-bottom: 70px;
  }
  .md-pb80 {
    padding-bottom: 80px;
  }
  .order2 {
    -webkit-box-ordinal-group: 3 !important;
    -ms-flex-order: 2 !important;
    order: 2 !important;
  }
  .order1 {
    -webkit-box-ordinal-group: 2 !important;
    -ms-flex-order: 1 !important;
    order: 1 !important;
  }
  .container {
    max-width: 720px;
  }
  .navbar {
    min-height: 60px;
  }
  .navbar .navbar-collapse {
    max-height: auto;
    overflow: auto;
    background: #11141b;
    text-align: center;
    padding: 10px 15px;
  }
  .navbar .nav-link {
    margin: 10px auto !important;
  }
  .navbar .logo {
    margin-left: 15px;
    width: 50px;
  }
  .nav-scroll .navbar-collapse .nav-link,
  .light .navbar-collapse .nav-link {
    color: #fff !important;
  }
  .nav-scroll .navbar-collapse .active,
  .light .navbar-collapse .active {
    color: #75dab4 !important;
  }
  .navbar .search {
    border: 0;
    padding: 0;
    margin-top: 30px;
  }
  .navbar .search .search-form {
    display: block !important;
    position: static;
    height: auto;
    opacity: 1 !important;
  }
  .navbar .search .search-form form {
    position: static;
    width: 100%;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
  }
  .navbar .search .search-form form input {
    padding: 15px;
    width: 100%;
    font-size: 14px;
  }
  .navbar .search .icon,
  .navbar .search .search-form .close {
    display: none;
  }
  .navbar-nav .dropdown-menu {
    opacity: 1;
    visibility: visible;
    display: none;
    background: #0c0f16;
    text-align: center;
    color: #eee;
  }
  .navbar-nav .dropdown-menu .dropdown-item {
    color: #eee;
  }
  .navbar-nav .dropdown-menu.show {
    display: block;
  }
  .navbar .navbar-nav .nav-link {
    padding: 0;
  }
  .navbar .navbar-nav .nav-link:hover {
    color: #75dab4;
  }
  .slider .parallax-slider .caption.dig {
    text-align: center;
  }
  .slider .parallax-slider .caption.dig h1 {
    font-size: 60px;
  }
  .slider .parallax-slider .caption.dig h1 .tline {
    margin-left: 0;
  }
  .slider .parallax-slider .caption h1 {
    font-size: 50px;
    letter-spacing: 1px;
  }
  .slider .parallax-slider .caption h1 br {
    display: none;
  }
  .showcase-full .parallax-slider .caption h1 .stroke {
    display: inline-block;
  }
  .showcase-full .parallax-slider .caption h1 span {
    margin-left: 0;
  }
  .showcase-carus .caption h1,
  .showcase-carus .copy-cap h1 {
    font-size: 60px;
    left: auto;
    margin-left: 20px;
  }
  .circle-slide .swiper-slide .bg-img {
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
    height: 380px !important;
    width: 380px !important;
    margin: auto !important;
  }
  .circle-slide .swiper-slide.swiper-slide-active .bg-img {
    opacity: 1;
    visibility: visible;
  }
  .showcase-carus.circle-slide .caption h1,
  .showcase-carus.circle-slide .copy-cap h1 {
    font-size: 65px;
  }
  .curve-bg {
    display: none;
  }
  .about .img-mons,
  .number-sec .item,
  .block-sec .vid-area .cont,
  .about .content,
  .skills-box {
    margin-bottom: 50px;
  }
  .about .blc-img .skills-circle {
    right: 0;
  }
  .about .blc-img .skills-circle .item {
    width: 50%;
  }
  .hero-sec .skills-box {
    padding-left: 0;
    margin-top: 50px;
  }
  .team .navs {
    position: absolute;
    top: 60px;
    right: 0;
    margin-top: 0 !important;
  }
  .team {
    position: relative;
  }
  .min-area .content,
  .min-area .img {
    padding: 120px 0;
  }
  .services .rtwo > div:nth-of-type(2),
  .services .rone > div:nth-of-type(2) {
    margin-bottom: 0px;
  }
  .sec-head.tr-head {
    text-align: center;
    margin-bottom: 55px;
  }
  .services .step-item.xbottom,
  .services .step-item.xtop {
    bottom: auto;
    top: auto;
  }
  .services .step-item {
    margin: 25px 0;
  }
  .block-sec .testim-box {
    margin-bottom: 120px;
  }
  .block-sec .showreel {
    background: #13161d;
  }
  .number-sec {
    padding-bottom: 70px;
  }
  .sec-head h3 br {
    display: none;
  }
  .sec-head.mb-0 {
    margin-bottom: 80px !important;
  }
  .blog-grid .item.active {
    margin: 50px 0;
  }
  .footer-half:after {
    width: 100%;
  }
  .footer-half .cont {
    margin-bottom: 5px;
  }
  .next-prog .box .caption h1,
  .next-prog .box .copy-cap h1 {
    font-size: 40px;
  }
}

@media screen and (max-width: 767px) {
  .sm-mb10 {
    margin-bottom: 10px;
  }
  .sm-mb20 {
    margin-bottom: 20px;
  }
  .sm-mb30 {
    margin-bottom: 30px;
  }
  .sm-mb40 {
    margin-bottom: 40px;
  }
  .sm-mb50 {
    margin-bottom: 50px;
  }
  .sm-mb60 {
    margin-bottom: 60px;
  }
  .sm-mb70 {
    margin-bottom: 70px;
  }
  .sm-mb80 {
    margin-bottom: 80px;
  }
  .sm-pb10 {
    padding-bottom: 10px;
  }
  .sm-pb20 {
    padding-bottom: 20px;
  }
  .sm-pb30 {
    padding-bottom: 30px;
  }
  .sm-pb40 {
    padding-bottom: 40px;
  }
  .sm-pb50 {
    padding-bottom: 50px;
  }
  .sm-pb60 {
    padding-bottom: 60px;
  }
  .sm-pb70 {
    padding-bottom: 70px;
  }
  .sm-pb80 {
    padding-bottom: 80px;
  }
  .container {
    max-width: 540px;
  }
  h1 {
    font-size: 35px;
  }
  h2 {
    font-size: 28px;
  }
  h3 {
    font-size: 24px;
  }
  h4 {
    font-size: 20px;
  }
  h5 {
    font-size: 18px;
  }
  h6 {
    font-size: 16px;
  }
  .progress-wrap {
    right: 15px;
    bottom: 15px;
  }
  .navbar .logo {
    width: 40px;
  }
  .hamenu .menu-links .main-menu > li {
    font-size: 24px;
  }
  .hamenu .menu-links .main-menu .sub-menu li {
    font-size: 18px;
  }
  .topnav .container-fluid {
    padding: 0 30px;
  }
  .topnav .logo {
    width: 40px;
  }
  .works-header {
    min-height: 60vh;
  }
  .btn-curve {
    padding: 8px 15px;
  }
  .btn-curve span {
    font-size: 15px;
    letter-spacing: 1px;
  }
  .slider .parallax-slider .caption h1 {
    font-size: 40px;
  }
  .slider .parallax-slider .caption h1 br {
    display: none;
  }
  .slider .parallax-slider .swiper-slide-active .caption h1 {
    font-size: 30px;
  }
  .slider .social-icon {
    display: none;
  }
  .slider .setone.setwo .prev-ctrl {
    left: 15px;
    bottom: 15px;
    top: auto;
    width: 45px;
    height: 45px;
    line-height: 45px;
  }
  .slider .setone.setwo .next-ctrl {
    left: 70px;
    bottom: 15px;
    top: auto;
    width: 45px;
    height: 45px;
    line-height: 45px;
  }
  .slider .swiper-pagination-fraction.top.botm {
    right: 15px;
    bottom: 15px;
  }
  .slider .swiper-pagination-fraction.top .swiper-pagination-current {
    font-size: 40px;
  }
  .slider .parallax-slider .caption.dig h1 {
    font-size: 40px;
  }
  .slider-bus .caption h1 {
    font-size: 70px;
  }
  .freelancer .cont h1 {
    font-size: 60px;
  }
  .slider-bus .setone {
    bottom: 20px;
    right: 20px;
  }
  .slider-bus .swiper-pagination-fraction {
    left: 20px;
    bottom: 20px;
  }
  .showcase-carus .swiper-slide .bg-img {
    margin: 0;
    height: 100vh;
    background-position: center center;
  }
  .showcase-carus.circle-slide .caption h1,
  .showcase-carus.circle-slide .copy-cap h1 {
    font-size: 50px;
    min-width: auto;
    margin: auto !important;
    left: 0;
  }
  .works-header .capt h2 {
    font-size: 40px;
  }
  .slider.fixed-slider,
  .works-header.hfixd {
    position: static;
  }
  .main-content {
    margin-top: 0 !important;
  }
  .about .img-mons .img1,
  .about .img-mons .img2,
  .about .img-mons .img3 {
    margin-bottom: 10px;
  }
  .about .img-mons .img3 {
    width: 100%;
    margin-top: 0;
  }
  .sec-head h3 {
    font-size: 45px;
  }
  .sec-head h6 {
    font-size: 1.1rem;
    letter-spacing: 5px;
  }
  .skills-circle {
    padding-bottom: 0 !important;
  }
  .skills-circle .item {
    margin-bottom: 50px;
  }
  .team .navs {
    top: 20px;
  }
  .block-sec .vid-area .cont h3 {
    font-size: 34px;
  }
  .call-action .content h2 {
    font-size: 9vw;
  }
  .portfolio .filtering span {
    margin: 5px 15px;
  }
  .team-crv .img-box .img.sizxl,
  .team-crv .img-box .img.sizmd,
  .team-crv .img-box .img.sizsm {
    width: 100%;
  }
  .team-crv .img-box .img.sizmd {
    margin-bottom: 30px;
  }

  [data-overlay-dark="2"]:before,
  [data-overlay-light="2"]:before {
  opacity: 0.55;
}

}

@media screen and (max-width: 480px) {
  .hamenu .cont-info {
    display: none;
  }
  .hamenu .menu-links .main-menu > li {
    font-size: 20px;
    padding: 7px;
  }
  .hamenu .menu-links .main-menu .sub-menu li {
    font-size: 16px;
  }
  .topnav .container-fluid {
    padding: 0 15px;
  }
  .pages-header .cont .path {
    font-size: 15px;
  }
  .pages-header .cont .path span {
    margin: 0 10px;
  }
  .slider-bus .caption h1 {
    font-size: 50px;
  }
  .slider .swiper-pagination-fraction.steps {
    bottom: 60px;
  }
  .circle-slide .swiper-slide .bg-img {
    height: 280px !important;
    width: 280px !important;
  }
  .showcase-carus.circle-slide .caption h1,
  .showcase-carus.circle-slide .copy-cap h1 {
    font-size: 40px;
  }
  .works-header .capt h2 {
    font-size: 30px;
    letter-spacing: 1px;
  }
  .sec-head h3 {
    font-size: 30px;
  }
  .about .blc-img .skills-circle {
    display: block;
    padding-bottom: 30px !important;
  }
  .about .blc-img .skills-circle .item {
    width: 100%;
  }
  .about .blc-img .skills-circle .item:last-of-type {
    margin-left: 0;
  }
  .portfolio .gallery .items {
    padding: 0 15px;
    margin: 0 0 50px;
  }
  .portfolio .gallery .items.lg-mr {
    margin-top: 50px;
  }
  .portfolio .filtering.smplx span {
    margin: 5px 15px;
  }
  .block-sec .vid-area .cont h3 {
    font-size: 28px;
  }
  .blog-curs .item {
    display: block;
  }
  .blog-curs .item .bimg {
    width: 100%;
  }
  .blog-curs .item .bimg .img {
    min-width: 220px;
  }
  .blog-curs .item .cont {
    width: 100%;
    padding: 30px 20px;
  }
  .next-prog .box .bg-img {
    width: 240px;
    height: 240px;
  }
  .next-prog .box .caption h1,
  .next-prog .box .copy-cap h1 {
    font-size: 20px;
    font-weight: 600;
  }
  .next-prog .box .caption h1 .stroke,
  .next-prog .box .copy-cap h1 .stroke {
    display: inline-block;
    color: #fff;
    -webkit-text-stroke: 0px #fff;
  }

  .testimonials .slick-slide {
    padding-left: 15px;
    padding-right: 15px;
  }

  .testimonials .item p {
    font-size: 16px;
    text-align: left;

    ;
  }

  .testimonials:hover .arrows .next,
  .testimonials:hover .arrows .prev {
    opacity: 0;
    right: 0;
  }
}

/* ====================== [ End Responsive ] ====================== */
