/*Profile card 2*/

.profile_card_2{
  border-radius: 14px !important;
  color:#000;
  border: none !important;
  border-color: none !important;
  background: none !important;
  max-width: 78%;
}

.profile_card_2 .fullsizebtn{
  width: 100%;
  background-color: grey;
  border: none;
  font-size: 28px;
}

.profile_card_2 .fullsizebtn:hover{
  background-color: var(--color-rose);
}

.profile_card_2 .card_img_block{
    float:left;
    width:100%;
    border-radius: 8px;
    overflow:hidden;
    text-align: center;
}

.table_small_padding td{
  padding: 0.25rem !important;
}

.table_small_padding th{
  padding: 0.25rem !important;
}


.card_img_block img {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid grey;
  overflow: hidden;
  margin: auto;
  object-fit: cover;
  max-width: 25.5rem;
}
.profile_card_2 .card_body{
    position:relative;
    background-color: #8b8b8b;
}
.profile_card_2 .profile {
  border-radius: 50%;
  position: absolute;
  top: -42px;
  left: 15%;
  max-width: 75px;
  border: 3px solid rgba(255, 255, 255, 1);
  -webkit-transform: translate(-50%, 0%);
  transform: translate(-50%, 0%);
}
.profile_card_2 h2{
    color:#000000;
}

.profile_card_2 h1 {
  color: #000;
}

.profile_card_2 p {
  color: #000;
}
.profile_card_2 .card_text{
    font-weight:300;
    font-size:15px;
    color: #000;
}
.profile_card_2 .icon_block{
    float:left;
    width:100%;
}
.profile_card_2 .icon_block a{
    text-decoration:none;
}

.apply {
  overflow: hidden;
  border-bottom-left-radius: 14px;
  border-bottom-right-radius: 14px;
  padding: 10px;
}

.profile_card_2 i {
  display: inline-block;
    font-size: 16px;
    color: #000000;
    text-align: center;
    border: 1px solid #ffffff;
    line-height: 30px;
    border-radius: 50%;
    font-size: 28px;
    margin:0 12px;
}
.profile_card_2 i:hover {
  background-color: var(--color-gold);
  color:#fff;
}

.cardBottom{
color: white;
font-weight: 700;
border-bottom-left-radius: 14px;
border-bottom-right-radius: 14px;
background-color: #454545;
}

.oneThird {
  width: 33%;
  float: left;
  padding: 20px 15px;
  border-right: 1px solid rgb(255, 255, 255);
}

.noBorder {
  border-right: none;
}

.clearfix:after {
  visibility: hidden;
display: block;
font-size: 0;
content: " ";
clear: both;
height: 0;
}