.qualification ul{
  display: flex;
}

.imgSmall{
  width: 92%;
  margin-bottom: 20px;
  margin-left: 15px;
}

.list{
  list-style: circle !important;
}

.list li{
  list-style: circle !important;
}

.bgImg::after{
content: " ";
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
background: #2b2d30;
opacity: 0.1;
z-index: 0;
}

.smallerImg{
  width: 80%;
}

.title,
  .title:hover,
  .title:active,
  .title:visited,
  .title:focus {
    text-decoration: none;
    cursor: pointer;
    color: blue;
  }

  .dots span{
    background: #d18787 !important;
    opacity: 0.8 !important;
  }

.text ul li{
  list-style:disc;
  padding-bottom: 5px;
  font-weight: 400;
}
.text ul{
  padding-left: 5px;
}
.text li{
  font-weight: 300;
}
.threeDots{
  position: relative;
}

.threeDots span{
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: #d18787;
  position: absolute;
  left: -10px;
  bottom: 10px;
}

.threeDots span:first-of-type{
  bottom: 16px;
}

.threeDots span:last-of-type{
  left: -16px;
}

.firstLevel{
  padding-bottom: 15px!important;
}

.text li:not(.firstLevel){
  list-style:circle;
  font-weight: 200;
}


.innerList{
  padding-left: 2rem !important;
}

.pushRowUp{
  margin-top: 80px;
}

@media only screen and (max-width: 800px){
  .text ul{
    padding-left: 20px;
  }

  .smallerImg{
    width: 100%;
  }

  .mobileBigTopPadding{
    padding-top: 0px !important;
  }

  .pushRowUp{
    margin-top: -20px;
  }
}
