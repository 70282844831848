/* Lato */
@font-face {
  font-family: 'Lato-Regular';
  src: url('../../../public/assets/fonts/Lato/Lato-Regular.ttf') format('truetype');
  font-weight: 300; /* Black weight is typically 900 */
  font-style: normal;
}

@font-face {
  font-family: 'Lato-Light';
  src: url('../../../public/assets/fonts/Lato/Lato-Light.ttf') format('truetype');
  font-weight: 200; /* Black weight is typically 900 */
  font-style: normal;
}

@font-face {
  font-family: 'Lato-Thin';
  src: url('../../../public/assets/fonts/Lato/Lato-Thin.ttf') format('truetype');
  font-weight: 100; /* Black weight is typically 900 */
  font-style: normal;
}

@font-face {
  font-family: 'Lato-Bold';
  src: url('../../../public/assets/fonts/Lato/Lato-Bold.ttf') format('truetype');
  font-weight: 400; /* Black weight is typically 900 */
  font-style: normal;
}

@font-face {
  font-family: 'Lato-Black';
  src: url('../../../public/assets/fonts/Lato/Lato-Bold.ttf') format('truetype');
  font-weight: 600 !important; /* Black weight is typically 900 */
  font-style: normal;
}
