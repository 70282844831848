.logo{
  width: 50px;
  margin-left: 15px;
  margin-top:0.5rem;
}

.navItem:hover {
  background-color: #383838;
}

.navButton{
  margin: 5px;
}

@media only screen and (max-width: 900px){
  .mobileImg{
      width: 85% !important;
      padding-top: 5px;
  }
}
