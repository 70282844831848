.quote h1{
  font-weight: 200;
  font-size: 26px;
}


.moveUpLeft{
  position: absolute;
  top: -10px;
  left: -20px;
}

.moveDownRight{
  position: absolute;
  bottom: 0px;
  right: 0px;
}

.blockquote {
  font-size: 2.5rem;
}

.blockquote cite {
  display: block;
  text-align: right;
  font-family: Verdana, Arial, sans-serif;
  margin-top: 1rem;
  font-size: .9rem;
  color: #d18787;
  font-style: normal;
}
.blockquote q {
  font-family: Georgia, serif;
  font-style: italic;
  letter-spacing: .1rem;
}
.blockquote q span {
  will-change: opacity, filter;
  opacity: 0;
  filter: blur(0px);
}

.blockquote q {
  quotes: "“" "”" "‘" "’";
}

.blockquote q:before {
    content: open-quote;
    margin-right: .8rem;
}
.blockquote q:after {
  content: close-quote;
}

.blockquote q:before, .blockquote q:after {
  color: #ccc;
  font-size: 4rem;
}

.bgImg{
  background-image: url("../../../public/assets/img/generell/Hintergrundbild.jpg");
  background-size: cover;
  background-position-y: -10rem;
}

@media only screen and (max-width: 800px){
  .blockquote {
    padding: 10px !important;
    font-size: 28px !important;
    text-align: center;
  }
  .blockquote q::after, .blockquote q::before{
    font-size: inherit;
  }

  .mobileOrder1{
    order: 1;
  }
  .mobileFlexBase1{
    flex-basis: auto;
  }
}
