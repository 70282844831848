
.video iframe{
  height: 30rem;
  width: 100%;
}

.searchResults{
  z-index: 1000;
  transform-origin: top;
  margin-top: .4rem;
  top: 100%;
  position: absolute;
  box-shadow: 0 2px 4px rgba(0,0,0,.08), 0 4px 12px rgba(0,0,0,.16);
  border: 1px solid #d1d7dc;
  background: white;
  left: auto;
  right: -1rem;
  width: 20rem;
}

.searchResults li{
  color: black;
}

.roseOnHover:hover,
.roseOnHover:active{
  color:var(--color-rose)
}

.searchResults li a {
  height: auto;
  text-align: left;
  vertical-align: baseline;
  white-space: normal;
  padding: .1rem
}

.searchForm{
  position: relative;
}

.postImg{
  width: 30%;
  height: 30%;
  margin: 20px;
}

@media only screen and (max-width: 800px) {
  .video iframe{
    height: 25rem;
    width: 100%;
  }
}