.rose{
  color: var(--color-rose) !important
}
 
.gold{
  color: var(--color-gold) !important
}

.main-text-color{
  color: var(--color-main-text) !important
}

.secondary-text-color {
  color: var(--color-secondary-text) !important
}

.fa-facebook, .fa-facebook-square {
  color: #3b5998
}
.fa-twitter, .fa-twitter-square {
  color: #00aced
}
.fa-google-plus, .fa-google-plus-square {
  color: #dd4b39
}
a:hover .fa-youtube{
  color: #bb0000
}
a:hover .fa-linkedin-in{
  background-color: #3375b4;
  border-radius: 20%;
}
.fa-vine {
  color: #00bf8f
}
.fa-flickr {
  color: #ff0084
}
a:hover .fa-envelope{
  background: #d6249f;
  background: linear-gradient(75deg, #4285F4 0 25%, #34A853 25% 50%,#FbbC05 50% 75% ,#EA4335 75% 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.fa-pinterest, .fa-pinterest-square {
  color: #cb2027
}
.fa-linkedin, .fa-linkedin-square {
  color: #007bb6
}
a:hover .fa-instagram {  
  background: #d6249f;
  background: radial-gradient(circle at 30% 100%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.fa-spotify {
  color: #1ED760;
}