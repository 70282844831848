.service h1{
font-weight: 200;
font-size: 2rem;
letter-spacing: 1px;
font-family: Lato-regular;
text-align: center;
}

.image{
  width: 70% !important;
}

.service h6{
  font-weight: 200;
  font-size: 46px;
  letter-spacing: 1px;
  text-align: center;
  font-size: 2rem;
}

.subTitle {
  position: relative;
  padding-left: 20px;
  margin-bottom: 20px;
}
.service p {
  font-size: 22px;
  text-align: center;
}

.service a {
    max-width: 240px;
    margin: auto;
}


.subTitle span {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: #d18787;
  position: absolute;
  left: 6px;
  bottom: 5px;
}

.subTitle span:first-of-type {
  bottom: 11px;
}
.subTitle span:last-of-type {
  left: 0px;
}

.goals {
  position: relative;
  margin: 0 auto 80px;
}
.goals h6 {
  color: #ffd700 ;
  font-weight: 300;
  font-size: 20px;
  text-transform: uppercase;
  letter-spacing: 14px;
  margin-bottom: 5px;
}
.goals h3 {
  font-size: 80px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 4px;
  text-shadow: 0 8px rgba(255, 255, 255, 0.05);
}
.goals .tbg {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  font-size: 10vw;
  font-weight: 700;
  color: transparent;
  -webkit-text-stroke: 1px rgba(255, 255, 255, 0.1);
  opacity: 0.3;
}
.goals.tr-head {
  text-align: right;
  margin-bottom: 0;
}
.goals.tr-head .tbg {
  text-align: left;
}

.bgMobile{
  font-size: 7rem !important;
  bottom: 4rem !important;
}

@media only screen and (max-width: 800px) {
  .service p{
    font-size: 18px !important;
    text-align: left;
    padding-left: 15px;
  }

  .service h1{
    font-size: 1.3rem;
  }

  .mobileHeading{
    font-size: 2rem !important;
  }

  .service h6{
    font-size: 1.3rem!important;
    font-weight: 200;
    letter-spacing: normal;
    padding: 10px;
  }

  .goals h3 {
    font-size: 3.5rem;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 4px;
    text-shadow: 0 8px rgba(255, 255, 255, 0.05);
  }

  .bgMobile{
    font-size: 3rem !important;
    bottom: 11rem !important;
  }

  .mobileH6{
    font-size: 0.9rem;
  }

  .goals{
    margin: 0 auto -60px;
  }

  .bigMobilePadding{
    padding-top: 5px!important;
  }

  .bigMobilePadding > div:first-of-type{
    margin-bottom: 0px;
  }

  .bigMobilePadding > div:last-of-type{
    margin-top: -80px;
  }

  .smallLineHeight{
    line-height: 3.5rem;
  }
}
